import React, { useState } from "react";
import axios from "axios";
import { url } from "../Config";
import './login.css';
// import { useNavigate } from "react-router-dom";

function Login({ handleLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // const navigate = useNavigate();

  const login = () => {
    axios.post(`${url}/api/users/login`, {
      username: username,
      password: password,
    })
      .then((response) => {
        if (!response.data.auth) {
          setErrorMessage("Invalid username or password");
        } else {
          const { token } = response.data;
          localStorage.setItem("token", token);
          handleLogin(token);
          // navigate('/categories');
          window.location.pathname = '/';

        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("حدث خطأ، يرجى المحاولة مرة أخرى.");
      });
  };

  return (
    <div className="login">
      <h1>تسجيل الدخول</h1>

      <input
        type="text"
        id="username"
        placeholder="ادخل اسم المستخدم..."
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />

      <input
        type="password"
        id="password"
        placeholder="ادخل كلمة المرور..."
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <button onClick={login}>تسجيل الدخول</button>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default Login;
