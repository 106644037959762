import React, { useState, useEffect } from "react";
import { url, parseStyledText } from "../../Config";
import axios from "axios";
import Loading from "../../boxes/Loading";

import "./norep.css";

function Withresponse() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  // ----------------------------------------------------------------------------------------------------
  // -----------------------------------------useEffect------------------------------------------------
  // ----------------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchMessages(); // Fetch messages when the component mounts
  }, []);


  // ----------------------------------------------------------------------------------------------------
  // ------------------------------------------fetch-----------------------------------------------------
  // ----------------------------------------------------------------------------------------------------

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${url}/admin/norep/withrep/mssg`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
        setLoading(false);
      } else {
        console.error(
          "Error fetching messages:",
          response.status,
          response.statusText
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setLoading(false);
    }
  };


  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------

  if (loading) {
    return (
      <>
        {" "}
        <Loading />
      </>
    );
  }

  return (
    <div>
      <table
        style={{ borderCollapse: "collapse", border: "1px solid black" }}
        className="custom-tabl"
      >
        <thead>
          <tr>
            <th>الرقم</th>
            <th>المحتوى</th>
            <th>الإجابة</th>
          </tr>
        </thead>
        <tbody>
          {messages &&
            [...messages].reverse().map((message, key) => (
              <tr key={message.id}>
                <td >{key + 1}</td>
                <td >{message.message}</td>
                <td >
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    width:'200px',
                    margin: '0px',
                    textAlign: 'right',
                    lineHeight: '1.5',
                    padding: '0px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                  dir='rtl' dangerouslySetInnerHTML={{ __html: parseStyledText(message.response) }} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Withresponse;
