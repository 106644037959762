import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Categories from "../../DashboardItems/categories/Categories";
import Socialmessages from "../../DashboardItems/Socialmessages/Socialmessages";
import Subcategories from "../../DashboardItems/subCategories/Subcategories";
import Register from "../../Login/Register";
import Usermessages from "../../DashboardItems/usermessages/Usermessages";
import Fileupload from "../../DashboardItems/Fileupload"
import "./content.css";
import Noresponse from "../../DashboardItems/noresponse/Noresponse"
import Question from "../../DashboardItems/noresponse/Question"
import Withresponse from "../../DashboardItems/noresponse/Withresponse"
import Tuto from "../../DashboardItems/tutoriel/tuto-cont/Tuto";
import Forbidden from "../../DashboardItems/Forbidden";
import Quotes from "../../DashboardItems/charts/Quotes";


function Content({ userRole ,longname }) {
  const isAdmin = userRole === 'admin';
  const isSousadmin = userRole === 'sousadmin';
  const isSupAdmin = userRole === 'supadmin';

  return (
    <div id="content">
      <Routes>
        {isSupAdmin && (
          <>
            <Route
              path="/register"
              element={<Register userRole={userRole} />}
            />
            <Route
              path="/Socialmessages"
              element={<Socialmessages />}
            />
          </>
        )}

        {(isAdmin || isSupAdmin)  && (
          <>
            <Route
              path="/Fileupload"
              element={<Fileupload />}
            />
            <Route
              path="/categories"
              element={<Categories userRole={userRole}/>}
            />
            <Route
              path="/subcategories"
              element={<Subcategories userRole={userRole}/>}
            />
          </>
        )}

        {(isAdmin || isSousadmin || isSupAdmin) && (
          <>
            <Route
              path="/Usermessages"
              element={<Usermessages userRole={userRole}/>}
            />
            <Route
              path="/Question"
              element={<Question userRole={userRole} />}
            />
            <Route
              path="/Question/Withresponse"
              element={<Withresponse/>}
            />
            <Route
              path="/Question/Noresponse"
              element={<Noresponse userRole={userRole} />}
            />
            <Route
              path="/Tuto"
              element={<Tuto userRole={userRole}/>}
            />
            <Route
              path="/"
              element={<Quotes  longname={longname} />}
            />
            <Route
              path="*"
              element={<Forbidden />}
            />
            <Route
              path="/forbidden"
              element={<Forbidden />}
            />
          </>
        )}

        <Route
          path="/forbidden"
          element={<Navigate to="/forbidden" />} 
        />
      </Routes>
    </div>
  );
}

export default Content;
