// Dashboard.js
import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Content from "../content/Content";
import "./dashboard.css";
import { BiLogOut } from "react-icons/bi";
import { Link } from "react-router-dom";
import { MdGroupAdd, MdInfo } from "react-icons/md";
import Modal from "../../boxes/Modal";

function Dashboard({ userRole, handleLogout, longname }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="bar-panel">
      <div id="topbar">
        <div className="tobarbtn">
          <MdInfo
            onClick={openModal}
            style={{ cursor: "pointer", color: "#03506c" }}
            size="1.5em"
          />
          {userRole === "supadmin" && (
            <Link to="/register">
              <span>
                <MdGroupAdd
                  style={{ cursor: "pointer", color: "#03506c" }}
                  size="1.5em"
                />
              </span>
            </Link>
          )}
          <BiLogOut
            onClick={handleLogout}
            style={{ cursor: "pointer", color: "#03506c" }}
            size="1.5em"
          />
        </div>
      </div>
      <div className="side">
        <Sidebar userRole={userRole} handleLogout={handleLogout} />
      </div>
      <div className="content">
        <Content userRole={userRole} longname={longname} />
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="custom-modal">
          <p>
            عزيزي(ة) <strong>{longname}</strong>، يرجى الالتزام بالشروط التالية
            عند إدخال البيانات وخاصة فيما يتعلق بالوسوم:
          </p>
          <ul>
            <li>
              <table>
                <tbody>
                  <tr>
                    <td>لا يجب إدخال كلمة تحتوي على حرفين متتابعين مثل:</td>
                    <td>ممنوع، للجمال، سكك، تنن، ممدود، رقق ...</td>
                  </tr>
                  <tr>
                    <td>بدلًا من ذلك، ادخل: </td>
                    <td> منوع، لجمال، سك، تن، مدود، رق</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>تُحذف تلقائيا الكلمات المتكررة في الوسوم مثل:</li>
            <div style={{padding:'10px',borderRadius:'10px' ,border:'1px solid black', backgroundColor:'	#FFFACD'}}>
              ان، وهي، في، على، ما، هل، كيف، من، لا، بين، أو، غير، إلى، لماذا،
              يجب، كان، عند، قبل، بعد، أين، الذي، مع، أيّ، شيء، كلّ، بكثرة، هذه،
              هذا، قليلاً، فوق، تحت، جداً، عادةً، حتى، مثل، عليه، به، منذ، إذا،
              بدون، حول، أكثر، كم، و، أو، ثم، بسبب، لكن، وقد، فيما، كثيراً، إذ،
              إذن، عليها، عليهم، عليهن، أولاً، ثانياً، بالتالي، بالإضافة، إليه،
              إليها، أيضاً، كأن، لديه، لديها، لكنّ، بلا، حيث، بأن، إنه، إنّما،
              كأنّه، كأنّها، بنا، تلك، تلكم، تلكن، ذلك، ذلكم، ذلكن، هؤلاء، ولكن،
              مثله، مثلهم، مثلهن، كأنّهم، كأنّهن، هنا، هنالك، هناك، ومن، هذين،
              هاتان، هذان، ذان، أيّان، كيفما، أينما، بما، أيضًا، أولاء، عل،
              هٰذا، هٰذه، هيهات ...
            </div>
            <li>تُحذف تلقائيا الأرقام والرموز والحروف اللاتينية.</li>
            <li>
            الحرص على حذف الوسوم الفارغة أو المساحات الفارغة في بداية
              ونهاية الوسوم.
            </li>
          </ul>
          <p>
            بعد الانتهاء من إدخال البيانات، يجب تحديث كل الفئات بالضغط على الزر{" "}
            <span role="img" aria-label="refresh">
              ♻️
            </span>
            الموجود في أعلى الفئة.
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
