

import React, { useState, useEffect } from 'react';
import { url } from '../../Config';
import CategoryDetails from './CategoryDetails';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import './sub.css'
const Subcategories = ({ userRole }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isAdmin = userRole === 'admin';
  const isSousadmin = userRole === 'sousadmin';
  const isSupAdmin = userRole === 'supadmin';


  const location = useLocation();
  const { category } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get('category');
  
    if (!isSupAdmin && categoryParam === 'nlp_farid_secure') {
      navigate('/invalid-category-page'); 
    } else {
      setSelectedCategory(categoryParam);
    }
  }, [location, isSupAdmin]);
  

  // useEffect(() => {
  //   fetchCategories();
  //   const searchParams = new URLSearchParams(location.search);
  //   const categoryParam = searchParams.get('category');

  //   if (categoryParam) {
  //     setSelectedCategory(categoryParam);
  //   }
  // }, [location]);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories();
    }
  }, [selectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${url}/admin/categories/get-categories`, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        let categoadmin;

        if (isSupAdmin) {
          // If isSupAdmin is true, include all categories
          categoadmin = data.categories;
        } else {
          categoadmin = data.categories.filter(e => e.Category_id !== 1);
        }

        setCategories(categoadmin);
        setLoading(false); 
      } else {
        console.error('Failed to fetch categories');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  // const fetchSubcategories = async () => {
  //   try {
  //     const response = await fetch(`${url}/admin/subcategories/subcategories-table/${selectedCategory}`, {
  //       method: 'GET',
  //       headers: {
  //         'x-access-token': localStorage.getItem('token'),
  //       },
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       setSubcategoryData(data.subcategories);
  //     } else {
  //       console.error('Failed to fetch subcategories');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const fetchSubcategories = async () => {
    try {
      if (!isSupAdmin && selectedCategory === 'nlp_farid_secure') {
        setSubcategoryData([]);
        navigate('/invalid-category-page');
        return;
      }
  
      const response = await fetch(`${url}/admin/subcategories/subcategories-table/${selectedCategory}`, {
        method: 'GET',
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setSubcategoryData(data.subcategories);
      } else {
        navigate('/invalid-category-page');
        console.error('Failed to fetch subcategories');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);
    navigate(`/subcategories?category=${selectedCategory}`);
  };

  return (
    <div>
      {/* <label htmlFor="selectCategory">اختر الفئة : </label> */}
      <select
        id="selectCategory"
        onChange={handleCategorySelect}
        value={selectedCategory}
      // placeholder='الفئات'
      >
        {/* <option value=""> الفئات</option> */}
        {categories.map((category) => (
          <option key={category.Category_name} value={category.Category_name}>
            {category.Category_name}
          </option>
        ))}
      </select>

      <hr />



      {selectedCategory && (
        <CategoryDetails selectedCategory={selectedCategory} />
      )}
    </div>

  );
};

export default Subcategories;
