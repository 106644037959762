import React, { useState, useEffect ,useRef } from 'react';
import { url, formatInputString , parseStyledText ,generatecleantags } from '../../Config'
import { MdOutlineAdd } from 'react-icons/md';
import './addrows.css'

const AddRow = ({ userRole, Norepmssg, Norepmssgtags, ondelete }) => {
  const [categories, setCategories] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const isAdmin = userRole === 'admin';
  const isSousadmin = userRole === 'sousadmin';
  const isSupAdmin = userRole === 'supadmin';

  const textareaRef = useRef(null);



  const [newMessage, setNewMessage] = useState({
    message: Norepmssg.message,
    tags: Norepmssgtags,
    response: '',
    options: [],
  });

  const [newTag, setNewTag] = useState(''); // State for a new tag
  const [newOption, setNewOption] = useState({
    label: '',
    type: 'text', // Default type is 'text'
    value: '',
  }); // State for a new option


  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories();
    }
  }, [selectedCategory]);








  const fetchCategories = async () => {
    try {
      const response = await fetch(`${url}/admin/categories/get-categories`, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        let categoadmin;

        if (isSupAdmin) {
          // If isSupAdmin is true, include all categories
          categoadmin = data.categories;
        } else {
          categoadmin = data.categories.filter(e => e.Category_id !== 1);
        }

        setCategories(categoadmin);
      } else {
        console.error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error:', error)
    }
  };

  const fetchSubcategories = async () => {
    try {
      const response = await fetch(`${url}/admin/subcategories/subcategories-table/${selectedCategory}`, {
        method: 'GET',
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSubcategoryData(data.subcategories);
      } else {
        console.error('Failed to fetch subcategories');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);
    setSelectedSubcategory('');
  };

  const handleSubcategorySelect = (e) => {
    const selectedSubcategory = e.target.value;
    setSelectedSubcategory(selectedSubcategory);
  };

















  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMessage({ ...newMessage, [name]: value });
  };

  const handleTagInputChange = (e) => {
    setNewTag(e.target.value);
  };

  function normalizeArabic(text) {
    return text
      .replace(/[أإآ]/g, 'ا')
      // .replace(/ة/g, 'ه')    
      // .replace(/ى/g, 'ي') 
      .replace(/[ًٌٍَُّ]/g, '');
  }
  const handleAddTag = () => {
    if (newTag.trim() !== '') {
      setNewMessage({ ...newMessage, tags: [...newMessage.tags, formatInputString(normalizeArabic(generatecleantags(newTag)))] });
      setNewTag('');
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...newMessage.tags];
    updatedTags.splice(index, 1);
    setNewMessage({ ...newMessage, tags: updatedTags });
  };

  const handleOptionInputChange = (e) => {
    const { name, value } = e.target;
    setNewOption({ ...newOption, [name]: value });
  };

  const handleAddOption = () => {
    if (newOption.label.trim() !== '' && newOption.value.trim() !== '') {
      setNewMessage({ ...newMessage, options: [...newMessage.options, newOption] });
      setNewOption({
        label: '',
        type: 'text',
        value: '',
      });
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...newMessage.options];
    updatedOptions.splice(index, 1);
    setNewMessage({ ...newMessage, options: updatedOptions });
  };



  const handleAddMessage = async (newMessage) => {
    // Check if the message is empty or only contains whitespace
    if (!selectedSubcategory || !selectedCategory || !newMessage.response.trim()) {
      // alert('Response cannot be empty. and select category and subcategory');
      alert('Response cannot be empty.');
      return;
    }

    try {
      const response = await fetch(`${url}/admin/usermessages/add/${selectedCategory}/${selectedSubcategory}`, {
        method: 'POST',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMessage),
      });

      if (response.ok) {
        await ondelete(Norepmssg.id)
        // alert(`message added to ${selectedCategory} ${selectedSubcategory}`)
      } else {
        // Handle the error case more gracefully
        console.error('Error adding message:', response.status, response.statusText);
      }

      // Reset the input fields and state
      setNewMessage({
        message: '',
        tags: [],
        response: '',
        options: [],
      });
      setNewTag('');
      setNewOption({
        label: '',
        type: 'text',
        value: '',
      });
    } catch (error) {
      // Handle unexpected errors
      console.error('Error adding message:', error);
    }
  };


  const insertTextWithFormatting = (startTag, endTag) => {
    const textarea = textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;

    // Get the selected text
    const selectedText = value.substring(selectionStart, selectionEnd);

    let newValue;

    switch (startTag) {
      case '*':
        // Bold text
        newValue = `${value.substring(0, selectionStart)}${startTag}${selectedText}${endTag}${value.substring(selectionEnd)}`;
        break;
      case '_':
        // Italic text
        newValue = `${value.substring(0, selectionStart)}${startTag}${selectedText}${endTag}${value.substring(selectionEnd)}`;
        break;
      case '~':
        // Underline text
        newValue = `${value.substring(0, selectionStart)}${startTag}${selectedText}${endTag}${value.substring(selectionEnd)}`;
        break;
      case '==':
        // Highlighted text (yellow background)
        newValue = `${value.substring(0, selectionStart)}${startTag}${selectedText}${endTag}${value.substring(selectionEnd)}`;
        break;
      case '@@red':
        // Red text
        newValue = `${value.substring(0, selectionStart)}${startTag}${selectedText}${endTag}${value.substring(selectionEnd)}`;
        break;
      case '@@green':
        // Green text
        newValue = `${value.substring(0, selectionStart)}${startTag}${selectedText}${endTag}${value.substring(selectionEnd)}`;
        break;
      case '%':
        // Insert URL with link text
        // const url = prompt('Enter URL:');
        // if (url) {
        //   const linkText = prompt('Enter link text:');
        //   if (linkText) {
        newValue = `${value.substring(0, selectionStart)}[${'urltext'}](${'url'})${value.substring(selectionEnd)}`;
        //   }
        // }
        break;
      case '-':
        // Insert unordered list item
        // const listItem = prompt('Enter list item:');
        // if (listItem) {
        newValue = `${value.substring(0, selectionStart)}- ${selectedText}${value.substring(selectionEnd)}`;
        // }
        break;
      default:
        // Default case (no formatting)
        // newValue = value;
        break;
    }

    // Update the textarea value and move the cursor position
    textarea.value = newValue;
    textarea.focus();
    textarea.selectionStart = selectionStart + startTag.length;
    textarea.selectionEnd = selectionStart + startTag.length + selectedText.length;
  };

  const handlestyleclick = (style) => {
    switch (style) {
      case 'bold':
        insertTextWithFormatting('*', '*');
        break;
      case 'italic':
        insertTextWithFormatting('_', '_');
        break;
      case 'underline':
        insertTextWithFormatting('~', '~');
        break;
      case 'highlight':
        insertTextWithFormatting('==', '==');
        break;
      case 'red':
        insertTextWithFormatting('@@red', '@@');
        break;
      case 'green':
        insertTextWithFormatting('@@green', '@@');
        break;
      case 'url':
        insertTextWithFormatting('%', '%');
        break;
      case 'ul':
        insertTextWithFormatting('-', '');
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div>
        <select className='selectCategory' value={selectedCategory} onChange={handleCategorySelect}>
          <option value="">اختر فئة </option>
          {categories.map((category) => (
            <option key={category.Category_id} value={category.Category_name}>
              {category.Category_name}
            </option>
          ))}
        </select>
        <select className='selectCategory' value={selectedSubcategory} onChange={handleSubcategorySelect}>
          <option value="">اختر فئة فرعية</option>
          {subcategoryData.map((subcategory) => (
            <option key={subcategory.id} value={subcategory.subcategorie_name}>
              {subcategory.subcategorie_name}
            </option>
          ))}
        </select>
      </div>
      {selectedSubcategory && selectedCategory ? (
        <div>
          <MdOutlineAdd
            onClick={() => handleAddMessage(newMessage)}
            style={{ cursor: 'pointer', color: 'green', margin: '0', position: 'absolute', top: '20px', right: '9px' }}
            size='2em'
            id='btn-add'
          />
          {/* <h2>Add New Message</h2> */}
          <div>
            <hr></hr>
            {/* <h3>message</h3> */}
           <input
              name="message"
              placeholder="السؤال"
              value={newMessage.message}
              onChange={handleInputChange}
              // style={{ width: '100%', height: '10px' }}
            />
            <hr></hr>

            <div>
              <input
                style={{ width: '50%', marginLeft: '5px' }}
                type="text"
                name="tags"
                placeholder="الوسوم"
                value={newTag}
                onChange={handleTagInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddTag();
                  }
                }}
              />
              <button onClick={handleAddTag}>+</button>
            </div>
            <div>
              {/* <h3>Tags</h3> */}
              <ul className='uledit' >
                {newMessage.tags.map((tag, index) => (
                  <li className='liedit' key={index} style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
                    {tag.replaceAll('_', ' ')}
                    <button onClick={() => handleRemoveTag(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
                  </li>
                ))}
              </ul>
            </div>
            <hr></hr>
        <div style={{ border: '1px solid black', paddin: '5px', marginBottom: '10px' }}>
          <div id="texteditor">
            <span onClick={() => handlestyleclick('bold')} style={{ fontWeight: 'bold' }}>A</span>
            <span onClick={() => handlestyleclick('italic')} style={{ fontStyle: 'italic' }}>A</span>
            <span onClick={() => handlestyleclick('underline')} style={{ textDecoration: 'underline' }}>A</span>
            <span onClick={() => handlestyleclick('highlight')} style={{ backgroundColor: 'yellow' }}>A</span>
            <span onClick={() => handlestyleclick('red')} style={{ color: 'red' }}>A</span>
            <span onClick={() => handlestyleclick('green')} style={{ color: 'green' }}>A</span>
            <span onClick={() => handlestyleclick('ul')} >°°°</span>
            <span onClick={() => handlestyleclick('url')} >URL</span>
          </div>

          <textarea
            ref={textareaRef}
            name="response"
            placeholder="الجواب : يعد لقصور الكلوي هو ...."
            value={newMessage.response}
            onChange={handleInputChange}
            style={{ margin: '5px', width: '80%', minHeight: '100px', borderTop: 'none', borderBottom: 'none', outline: 'none' }}
          />
        </div>
        <div style={{
          overflow: 'auto',
          width: '100%',
          minHeight: '100px',
          border: '1px solid black',
          textAlign: 'right',
          lineHeight: '1.5', // Adjusted line height for better readability
          padding: '10px',
          whiteSpace: 'pre-wrap', // Allow wrapping within words
          wordBreak: 'break-word' 
        }} dir='rtl' dangerouslySetInnerHTML={{ __html: parseStyledText(newMessage.response) }} />
            {/* <textarea
              name="response"
              placeholder="الجواب"
              value={newMessage.response}
              onChange={handleInputChange}
              style={{ width: '100%', height: '100px' }}

            /> */}
            <hr className='newhr'></hr>
            <div>
              {/* <h3>Options</h3> */}
              <div>
                <input
                  type="text"
                  name="label"
                  placeholder="عنوان الاقتراح"
                  value={newOption.label}
                  onChange={handleOptionInputChange}
                  style={{ width: '70%', margin: '7px' }}
                />
                <select
                  name="type"
                  value={newOption.type}
                  onChange={handleOptionInputChange}
                  style={{ height: '40px', width: '20%', margin: '7px' }}
                >
                  <option value="text">نص</option>
                  <option value="link">رابط</option>
                  <option value="map">خريطة</option>
                  {/* <option value="number">رقم</option>
                <option value="mail">بريد</option>
                <option value="media">وسائط</option>
                <option value="map">خريطة</option> */}
                </select>
                <input
                  type="text"
                  name="value"
                  placeholder="محتوى الاقتراح"
                  value={newOption.value}
                  onChange={handleOptionInputChange}
                  style={{ width: '80%', margin: '7px' }}
                />
                <button onClick={handleAddOption}>+</button>
              </div>
              <ul className='uledit'>
                {newMessage.options.map((option, index) => (
                  <li key={index} className='liedit' style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%', marginLeft: '20px' }}>
                      <tbody>
                        <tr>
                          <th style={{ padding: '8px' }}>عنوان</th>
                          <td style={{ padding: '8px' }}>{option.label}</td>
                        </tr>
                        <tr>
                          <th style={{ padding: '8px' }}>النوع</th>
                          <td style={{ padding: '8px' }}>
                            {option.type === 'text'
                              ? 'نص'
                              : option.type === 'number'
                                ? 'رقم'
                                : option.type === 'link'
                                  ? 'رابط'
                                  : option.type === 'mail'
                                    ? 'بريد'
                                    : option.type === 'media'
                                      ? 'وسائط'
                                      : option.type === 'map'
                                        ? 'خريطة'
                                        : 'غير معروف'}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ padding: '8px' }}>محتوى</th>
                          <td style={{ padding: '8px' }}>{option.value}</td>
                        </tr>
                      </tbody>
                    </table>

                    <button onClick={() => handleRemoveOption(index)} style={{ position: 'absolute', top: '0px', left: '5px', marginRight: '10px', fontSize: '25px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }}>X</button>
                  </li>
                ))}
              </ul>
              {/* {newMessage.options.map((option, index) => (
                <div key={index}>
                  <div>
                    <span>Label: {option.label}</span>
                    <span>Type: {option.type}</span>
                    <span>Value: {option.value}</span>
                  </div>
                  <button onClick={() => handleRemoveOption(index)}>Remove</button>
                </div>
              ))} */}
            </div>
            {/* <button onClick={() => handleAddMessage(newMessage)}>Add</button> */}
          </div>
        </div>
      ) : null}


    </div>
  );
};

export default AddRow;
