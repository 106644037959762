import React, { useState } from 'react';
import { url, formatInputString } from '../../Config';
import Categorytable from './Categorytable.js';
import Modal from '../../boxes/Modal'
// import './Categories.css'
import './editcatego.css';
import { MdOutlineAdd } from 'react-icons/md';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

const Categories = ({ userRole }) => {
  const [categoryName, setCategoryName] = useState('');
  const [tag, setTag] = useState('');
  const [type, settype] = useState(1);
  const [tags, setTags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCategoryNameChange = (e) => {
    // Call the formatInputString function to format the input
    const formattedCategoryName = formatInputString(e.target.value);
    setCategoryName(formattedCategoryName);
  };

  const handleTagChange = (e) => {
    setTag(formatInputString(normalizeArabic(e.target.value)));
  };

  function normalizeArabic(text) {
    return text
      .replace(/[أإآ]/g, 'ا')
      // .replace(/ة/g, 'ه')    
      // .replace(/ى/g, 'ي') 
      .replace(/[ًٌٍَُّ]/g, '');
  }
  const handleTagKeyPress = (e) => {
    if (e.key === 'Enter' && tag.trim() !== '') {
      setTags([...tags, tag]);
      setTag('');
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags.filter((t) => t !== tagToRemove);
    setTags(updatedTags);
  };

  const handleSubmit = async () => {
    if (categoryName == '') {
      alert('Please fill category Name.');
      return;
    }
    try {
      const categoryData = { categoryName, type, tags };

      const response = await fetch(`${url}/admin/categories/add-category`, {
        method: 'POST',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(categoryData),
      });

      if (response.ok) {
        console.log('Category added successfully');
        setCategoryName('');
        setTags([]);
        closeModal()
        window.location.reload();
      } else {
        console.error('Failed to add category');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleTypeChange = (event) => {
    settype(parseInt(event.target.value)); // Parse the selected value as an integer
  };
  return (
    <div>
      <MdOutlineAdd
        onClick={openModal}
        style={{ cursor: 'pointer', color: 'green' }}
        size='2em'
      />
      <Modal isOpen={isModalOpen} onClose={closeModal}>

        {/* <h2>إضافة فئة</h2> */}

        <div className="form">
          {/* <label htmlFor="categoryName"></label> */}
          <input
            type="text"
            id="categoryName"
            value={categoryName.replaceAll('_', ' ')}
            onChange={handleCategoryNameChange}
            pattern="[A-Za-z]+"
            placeholder='اسم الفئة'
          />
        </div>

        {/* <div className="form-group">
          <label>اختر النوع:</label>
          <select value={type} onChange={handleTypeChange}>
            <option value={1}>تبان</option>
            <option value={0}>ماتبانش</option>
          </select>
          <p>النوع المحدد: {type === 1 ? "تبان" : "ماتبانش"}</p>
        </div> */}

        <div className="form-group">
          {/* <label>اختر النوع:</label> */}
          <div className="custom-radio-group">
            <label className={`custom-radio ${type === 1 ? 'active' : ''}`} onClick={() => settype(1)}>
              <input type="radio" value={1} checked={type === 1} onChange={() => { }} />
              <FaEye size='1.5em' />
              اضهار
            </label>
            <label className={`custom-radio ${type === 0 ? 'active' : ''}`} onClick={() => settype(0)}>
              <input type="radio" value={0} checked={type === 0} onChange={() => { }} />
              <FaEyeSlash size='1.5em' />
              اخفاء
            </label>
          </div>
          {/* <p>النوع المحدد: {type === 1 ? <FaEye size='1.5em' /> : <FaEyeSlash size='1.5em' />}</p> */}
        </div>


        {/* <div className="form-group">
          <label htmlFor="tags">الوسوم:</label>
          <input
            type="text"
            id="tags"
            value={tag}
            onChange={handleTagChange}
            onKeyPress={handleTagKeyPress}
          />
        </div>

        <div className="tags-container">
          {tags.map((t) => (
            <div key={t} className="tag">
              {t}
              <button onClick={() => handleTagRemove(t)} className="remove-button">
                &times;
              </button>
            </div>
          ))}
        </div> */}

        <button style={{ position: 'absolute', bottom: '20px', left: '9px' }} onClick={handleSubmit}>إضافة فئة</button>
      </Modal>
      <hr />
      <Categorytable userRole={userRole}  />
    </div>

  );
};

export default Categories;
