import React from 'react';
import * as XLSX from 'xlsx';
import { RiFileExcel2Fill } from 'react-icons/ri'

const ExportMessages = ({ data  , selectedSubcategory , selectedCategory }) => {
  const handleExport = () => {
    const exportData = data.map(message => ({
      name: message.message,
      data: message.response || '',
      category: selectedCategory,
      subcategory: selectedSubcategory,
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Exported Data');
    XLSX.writeFile(wb, 'exported-messages.xlsx');
  };

  return (
        <RiFileExcel2Fill
        onClick={handleExport}
        style={{ cursor: 'pointer', color: 'green' }}
        size="2em"
      />
  );
};

export default ExportMessages;
