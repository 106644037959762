import React from 'react';
import './modal.css'
import { IoMdCloseCircle } from 'react-icons/io';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
      <IoMdCloseCircle
          id='close'
          onClick={onClose}
          size='2em'
        />
        {children}
      </div>
    </div>
  );
};

export default Modal;









// import Modal from '../../boxes/Modal'
// const [isModalOpen, setIsModalOpen] = useState(false);

// const openModal = () => {
//   setIsModalOpen(true);
// };

// const closeModal = () => {
//   setIsModalOpen(false);
// };



// <button onClick={openModal}>Open Modal</button>
// <Modal isOpen={isModalOpen} onClose={closeModal}>
//   </Modal>