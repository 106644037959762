
import React, { useEffect, useState } from 'react';
import Chat from '../message/Chat';
import Resulttuto from '../Result/Resulttuto';
import { url, formatInputString } from '../../../Config';
import { IoIosSend } from "react-icons/io";
import axios from 'axios';
import './tuto.css'
import { IoIosSettings } from "react-icons/io";

function Tuto({ userRole }) {
  const [adminmssg, setAdminmssg] = useState('');
  const [mssg, setmssg] = useState('');
  const [chatmsg, setchatmsg] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (adminmssg !== '') {
      setLoading(true);
      setmssg(adminmssg)
      setAdminmssg('')
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [adminmssg]);



return (
  <div id='tuto-container'>
    <div id='tuto-mssg'><Chat setAdminmssg={setAdminmssg} setResult={setResult} setchatmsg={setchatmsg} /></div>
    <div id='tuto-result'>
      {loading ? (
        <IoIosSettings className="wheelturn" />
      ) : (
        result === null && chatmsg === '' ? (
          <IoIosSettings className="wheelfix" />
        ) : (
          <Resulttuto result={result} mssg={mssg} userRole={userRole}  chatmsg={chatmsg} />
        )
      )}
    </div>
  </div>
);
}

export default Tuto;
