import React, { useState } from 'react';
// import Modal from './Modal';
import './modaldelete.css'


export const DeleteModal = ({ onDeleteConfirmed, statedeletbtn }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    statedeletbtn();
  };

  const handleConfirmDelete = () => {
    onDeleteConfirmed();
    handleCloseModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modall">
      <div className="modall-content">

        <p>هل تريد الحدف ؟</p>
        <div id='btndeltmdl'>
          <button style={{ width: '90px', }} onClick={handleCloseModal}>لا</button>
          <button style={{ width: '90px', backgroundColor: 'red' }} onClick={handleConfirmDelete}>نعم</button>
        </div>
      </div>
    </div>
  );
};
