import React, { useState } from 'react';
import './Quotes.css';

function Greeting({longname}) {
    const dashboardName = "فريد"; 
    const adminName = longname;
    
    const quotes = [
        `مرحبًا بك  ${adminName}  في إدارة  ${dashboardName}.`,
        `أهلاً وسهلاً،  ${adminName} ! نتمنى لك يومًا سعيدًا ومليئًا بالنجاح في ${dashboardName}.`,
      ];
      
    const [randomQuote, setRandomQuote] = useState(quotes[Math.floor(Math.random() * quotes.length)]);

    const getRandomQuote = () => {
        const randomIndex = Math.floor(Math.random() * quotes.length);
        setRandomQuote(quotes[randomIndex]);
    }

    return (
        <div className="quotes-container">
            {/* <div className="card-front">
            <img src="./img/Drfarid.svg" width="70%" height="80%" alt="" />
          </div> */}
            <q className="quotes-text" onClick={getRandomQuote}>{randomQuote}</q>
        </div>
    );
}

export default Greeting;




// const quotes = [
//     `مرحبًا بك ${adminName} في إدارة ${dashboardName}.`,
//     `أهلاً وسهلاً، ${adminName}! نتمنى لك يومًا سعيدًا ومليئًا بالنجاح في ${dashboardName}.`,
//     `مرحبًا ${adminName}! توقع أوقاتًا مثمرة وتحديات ملهمة في رحلتك مع إدارة ${dashboardName}.`,
//     `أهلًا وسهلًا، ${adminName}! نحن متحمسون لرؤية الابتكارات التي ستضيفها إلى إدارة ${dashboardName}.`,
//     `تفضل بالاستمتاع برحلتك في إدارة ${dashboardName}، ${adminName}! سنكون هنا لدعمك.`,
//   ];
  
//   // Now you can use the quotes array in your component
//   // ...
  