import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiFileExcel2Fill } from 'react-icons/ri';
import { BiCategory } from "react-icons/bi";
import { BsChatTextFill } from "react-icons/bs";
import { TbUserQuestion } from "react-icons/tb";
import { IoIosChatbubbles } from "react-icons/io";
import { TbTestPipe } from "react-icons/tb";
import { IoPieChartSharp } from "react-icons/io5";
import "./sidebarnew.css";

const linksData = {
  supadmin: [
    {
      to: "/",
      text: "Charts",
      icon: <IoPieChartSharp  className="iconsidebar" />,
      submenu: ["لوحة البيانات"],
    },
    {
      to: "/categories",
      text: "Categories",
      icon: <BiCategory className="iconsidebar" />,
      submenu: ["تصنيفات"],
    },
    {
      to: "/Socialmessages",
      text: "Socialmessages",
      icon: <BsChatTextFill className="iconsidebar" />,
      submenu: ["الرسائل الاجتماعية"],
    },
    {
      to: "/Question",
      text: "Question",
      icon: <TbUserQuestion className="iconsidebar" />,
      submenu: ["رسائل بدون رد"],
    },
    {
      to: "/Fileupload",
      text: "Fileupload",
      icon: <RiFileExcel2Fill className="iconsidebar" />,
      submenu: ["تحميل ملف البيانات"],
    },
    {
      to: "/Tuto",
      text: "Tuto",
      icon: <TbTestPipe  className="iconsidebar" />,
      submenu: ["إختبار"],
    },
  ],
  admin: [
    {
      to: "/",
      text: "Charts",
      icon: <IoPieChartSharp  className="iconsidebar" />,
      submenu: ["لوحة البيانات"],
    },
    {
      to: "/categories",
      text: "Categories",
      icon: <BiCategory className="iconsidebar" />,
      submenu: ["تصنيفات"],
    },
    {
      to: "/Question",
      text: "Question",
      icon: <TbUserQuestion className="iconsidebar" />,
      submenu: ["رسائل بدون رد"],
    },
    {
      to: "/Fileupload",
      text: "Fileupload",
      icon: <RiFileExcel2Fill className="iconsidebar" />,
      submenu: ["تحميل ملف البيانات"],
    },
    {
      to: "/Tuto",
      text: "Tuto",
      icon: <TbTestPipe  className="iconsidebar" />,
      submenu: ["إختبار"],
    },
  ],
  sousadmin: [
    {
      to: "/",
      text: "Charts",
      icon: <IoPieChartSharp  className="iconsidebar" />,
      submenu: ["لوحة البيانات"],
    },
    {
      to: "/Usermessages",
      text: "Usermessages",
      icon: <IoIosChatbubbles className="iconsidebar" />,
      submenu: ["الرسائل"],
    },
    {
      to: "/Question",
      text: "Question",
      icon: <TbUserQuestion className="iconsidebar" />,
      submenu: ["رسائل بدون رد"],
    },
    {
      to: "/Tuto",
      text: "Tuto",
      icon: <TbTestPipe  className="iconsidebar" />,
      submenu: ["إختبار"],
    },
  ],
};

function Sidebar({ userRole, handleLogout }) {
  const location = useLocation();
  const [tooltipText, setTooltipText] = useState("");

  const handleMouseEnter = (text) => {
    setTooltipText(text);
  };

  const handleMouseLeave = () => {
    setTooltipText("");
  };

  const userLinks = linksData[userRole] || [];

  return (
    <div className="sidebar">
      <img src="/img/Drfarid.svg" alt="Bot" id="svgimg" />
      <div id='sidebar-item'>
        {userLinks.map((link, index) => (
          <Link
            key={index}
            onMouseEnter={() => handleMouseEnter(link.text)}
            onMouseLeave={handleMouseLeave}
            to={link.to}
            className={location.pathname === link.to ? "sidebar-link active" : "sidebar-link"}
          >
            <span>{link.icon}</span>
            <div className="tooltip">
              {tooltipText === link.text && (
                <div>
                  {link.submenu && link.submenu.map((item, subIndex) => (
                    <span key={subIndex}>{item}</span>
                  ))}
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
