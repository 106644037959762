import React, { useState, useEffect } from "react";
import { url, formatInputString } from "../../Config";
import Modal from "../../boxes/Modal";
import { MdDelete, MdEdit, MdOutlineAdd } from "react-icons/md";
import { BsHandIndexThumb } from "react-icons/bs";
import { FaRegHandPointRight } from "react-icons/fa";
import axios from "axios";
import AddRow from "./AddRow";
import Socialmessages from "./Socialmessages";
import Loading from "../../boxes/Loading";
import { DeleteModal } from "../../boxes/DeleteModal";

import "./norep.css";
import ExportMessages from "../../boxes/ExportMessages ";

function Noresponse({ userRole }) {
  const [messages, setMessages] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModalContent, setSelectedModalContent] = useState(null);
  const [deleted, setdeleted] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const isAdmin = userRole === "admin";
  const isSousadmin = userRole === "sousadmin";
  const isSupAdmin = userRole === "supadmin";
  const [deletingmessageid, setdeletingmessageid] = useState(null);
  const [deleteallmsgalert, setdeleteallmsgalert] = useState(null);

  // ----------------------------------------------------------------------------------------------------
  // -----------------------------------------useEffect------------------------------------------------
  // ----------------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchMessages(); // Fetch messages when the component mounts
  }, []);

  useEffect(() => {
    if (deleted == true) {
      fetchMessages();
      setdeleted(false);
    }
  }, [deleted]);

  useEffect(() => {
    if (tags.length !== 0) {
      setIsModalOpen(true);
      setSelectedModalContent("message");
      // console.log(tags)
    }
  }, [tags]);

  const openModal = async (message) => {
    setSelectedMessage(message);
    await setTags(generateTags(message.message));
  };

  const closeModal = () => {
    setSelectedMessage(null);
    setIsModalOpen(false);
  };

  // Function to toggle the selection of a row
  const toggleRowSelection = (messageId) => {
    const isSelected = selectedRows.includes(messageId);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((id) => id !== messageId));
      // console.log(selectedRows)
    } else {
      setSelectedRows([...selectedRows, messageId]);
      // console.log(selectedRows)
    }
  };

  // Function to handle selecting all rows
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allMessageIds = messages.map((message) => message.id);
      // console.log(allMessageIds)
      setSelectedRows(allMessageIds);
    }
    setSelectAll(!selectAll);
  };

  // Function to delete selected rows
  const deleteSelectedRows = async () => {
    // console.log('selectedRows',selectedRows)
    await deletALLemessage(selectedRows);
    setSelectedRows([]); // Clear the selected rows after deletion
    setSelectAll(false); // Clear the "Select All" checkbox
  };
  // ----------------------------------------------------------------------------------------------------
  // ------------------------------------------fetch-----------------------------------------------------
  // ----------------------------------------------------------------------------------------------------

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${url}/admin/norep/mssg`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
        setLoading(false);
      } else {
        console.error(
          "Error fetching messages:",
          response.status,
          response.statusText
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setLoading(false);
    }
  };

  const deletALLemessage = async (selectedRows) => {
    try {
      const response = await fetch(`${url}/admin/norep/mssg/deleteall/${selectedRows}`, {
        method: "DELETE",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        fetchMessages();
        setdeletingmessageid(null);
        closeModal();
        setdeleted(null);
      } else {
        console.error(
          "Error deleting message:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const deletemessage = async (id) => {
    try {
      const response = await fetch(`${url}/admin/norep/mssg/delete/${id}`, {
        method: "DELETE",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        fetchMessages();
        setdeletingmessageid(null);
        closeModal();
        setdeleted(null);
      } else {
        console.error(
          "Error deleting message:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const generateTags = (name) => {
    const excludedWords = [
      "في",
      "على",
      "ما",
      "هل",
      "كيف",
      "من",
      "لا",
      "بين",
      "هل",
      "أو",
      "غير",
      "أن",
      "إلى",
      "لماذا",
      "يجب",
      "كان",
      "عند",
      "قبل",
      "بعد",
      "أين",
      "الذي",
      "مع",
      "أيّ",
      "شيء",
      "كلّ",
      "بكثرة",
      "هذه",
      "هذا",
      "قليلاً",
    ];
    const newname = name
      .replace(/[!"#$%&'()*+,-.؟/:;<=>?@[\]^_`{|}~]/g, "")
      .trim();
    const words = newname
      .split(/\s+/)
      .filter((word) => !excludedWords.includes(word));

    const modifiedWords = words.map((word) =>
      word.replace(/[ًٌٍَُِّ\p{P}]/gu, "").trim()
    );
    const uniqueWords = [...new Set(modifiedWords)];

    const combinedWords = uniqueWords.map((word, index) => {
      if (index < uniqueWords.length - 1) {
        return `${word}_${uniqueWords[index + 1]}`;
      }
      return word;
    });

    // Combine three consecutive unique words
    const threeWordCombinations = [];
    for (let i = 0; i < uniqueWords.length - 2; i++) {
      threeWordCombinations.push(
        `${uniqueWords[i]}_${uniqueWords[i + 1]}_${uniqueWords[i + 2]}`
      );
    }

    // Concatenate the individual words, two-word combinations, and three-word combinations
    return [...uniqueWords, ...combinedWords, ...threeWordCombinations];
  };
  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------

  if (loading) {
    return (
      <>
        {" "}
        <Loading />
      </>
    );
  }

  return (
    <div>
      <table
        style={{ borderCollapse: "collapse", border: "1px solid black" }}
        className="custom-tabl"
      >
        <thead>
          <tr>
            <th style={{ width: "30px" }}>
              <MdDelete
                onClick={() => setdeleteallmsgalert(1)}
                style={{ cursor: "pointer", color: "red" }}
                size="1em"
              />
              <input
                type="checkbox"
                checked={selectAll}
                onChange={toggleSelectAll}
              />
            </th>
            <th>الرقم</th>
            <th>المحتوى</th>
            <th>الوسوم</th>
            {/* <th>الإجراءات</th> */}
            <th><ExportMessages data={messages} selectedSubcategory={''} selectedCategory={''} /></th>
          </tr>
        </thead>
        <tbody>
          {messages &&
            [...messages].reverse().map((message, key) => (
              <tr key={message.id}>
                <td style={{ padding: "0px" }}>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={selectedRows.includes(message.id)}
                    onChange={() => toggleRowSelection(message.id)}
                  />
                </td>
                <td onClick={() => openModal(message)}>{key + 1}</td>
                <td onClick={() => openModal(message)}>{message.message}</td>
                <td onClick={() => openModal(message)}>
                  <ul className="custom-list">
                    {generateTags(message.message).map((tag) => (
                      <li>#{tag}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <MdDelete
                    onClick={() => setdeletingmessageid(message.id)}
                    style={{ cursor: "pointer", color: "red" }}
                    size="2em"
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {selectedMessage && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {selectedModalContent === "message" ? (
            <div id="modalcontainer">
              <div id="mssgdetail">
                <table className="vertical-table">
                  <tr>
                    <th>
                      <div>الرسالة</div>
                    </th>
                    <td>{selectedMessage.message}</td>
                  </tr>
                  <tr>
                    <th>
                      <div>كلمات</div>
                    </th>
                    <td>
                      <ul className="custom-list">
                        {tags.map((tag) => (
                          <li>{tag}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
              <div id="mssgdecision">
                <p>اضف إلى :</p>
                <div id="handsuggest">
                  {isSupAdmin ? (
                    <>
                      <div
                        className="hand"
                        onClick={() => setSelectedModalContent("categories")}
                      >
                        <span>فئات</span>{" "}
                        <FaRegHandPointRight className="finger" />
                      </div>
                      <div
                        className="hand"
                        onClick={() =>
                          setSelectedModalContent("socialMessages")
                        }
                      >
                        <span>الرسائل الاجتماعية</span>{" "}
                        <FaRegHandPointRight className="finger" />
                      </div>
                    </>
                  ) : (
                    <div
                      className="hand"
                      onClick={() => setSelectedModalContent("categories")}
                    >
                      <span>فئات</span>{" "}
                      <FaRegHandPointRight className="finger" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {selectedModalContent === "categories" ? (
            <AddRow
              userRole={userRole}
              Norepmssg={selectedMessage}
              Norepmssgtags={tags}
              ondelete={(id) => deletemessage(id)}
            />
          ) : null}

          {selectedModalContent === "socialMessages" ? (
            <Socialmessages
              Norepmssg={selectedMessage}
              Norepmssgtags={tags}
              ondelete={(id) => deletemessage(id)}
            />
          ) : null}
        </Modal>
      )}

      {deletingmessageid && (
        <DeleteModal
          onDeleteConfirmed={() => deletemessage(deletingmessageid)}
          statedeletbtn={() => setdeletingmessageid(null)}
        />
      )}
      {deleteallmsgalert && (
        <DeleteModal
          onDeleteConfirmed={deleteSelectedRows}
          statedeletbtn={() => setdeleteallmsgalert(null)}
        />
      )}
    </div>
  );
}

export default Noresponse;
