
import React, { useState, useEffect } from 'react';
import { url, parseStyledText } from '../../Config';
import Modal from '../../boxes/Modal';
import { MdDelete, MdEdit } from 'react-icons/md';
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import ExportMessages from '../../boxes/ExportMessages ';
import { DeleteModal } from '../../boxes/DeleteModal';
import './mssg.css'


const MessagesTable = ({ messages, selectedCategory, selectedSubcategory, onEditMessage, setondelete, onDuplicate }) => {
  const [deletingmessageid, setdeletingmessageid] = useState(null);

  const handleEditMessage = (message) => {
    onEditMessage(message);
  };
  const handleduplicate = (message) => {
    onDuplicate(message);
  };

  const handleDeletemessage = async (id) => {
    try {
      const messagedata = { selectedCategory, selectedSubcategory };
      const response = await fetch(`${url}/admin/usermessages/delete-messg/${id}`, {
        method: 'DELETE',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messagedata),
      });
      if (response.ok) {
        setondelete(true)
        console.log('Message deleted successfully');
        // Add code here to trigger a refresh of messages if needed
      } else {
        console.error('Failed to delete message');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='mssg-container'>
      <table className='custom-tabl'>
        <thead>
          <tr>
            <th>ID</th>
            <th>المصطلح</th>
            <th>Tags</th>
            <th>معلومات عنه</th>
            <th>Options</th>
            <th> <ExportMessages data={messages} selectedSubcategory={selectedSubcategory} selectedCategory={selectedCategory} /></th>
          </tr>
        </thead>
        <tbody>
          {messages.reverse().map((message, indeex) => (
            <tr key={message.id}>
              {/* <td>{message.id}</td> */}
              <td>{indeex+1}</td>
              <td>{message.message}</td>
              <td>
                <ul className='custom-list'>
                  {JSON.parse(message.tags).map((tag, index) => (
                    <li style={{ backgroundColor: '#a6d5e478  !important' }} key={index}>#{tag}</li>
                  ))}
                </ul>
              </td>
              {/* <td>{message.response}</td> */}
              <td >
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    width:'200px',
                    margin: '0px',
                    textAlign: 'right',
                    lineHeight: '1.5',
                    padding: '0px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                  dir='rtl' dangerouslySetInnerHTML={{ __html: parseStyledText(message.response) }} />
              </td>
              <td id='optiontdx'>
                {JSON.parse(message.options).map((option, index) => (
                  <div key={index} className='option-boxx' style={{ backgroundColor: '#a6d5e4', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                    {option.type === 'link' ? (
                      <a href={option.value} target="_blank" style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                        {option.label}
                      </a>
                    ) : (
                      <span>{option.label}</span>
                    )}
                  </div>
                ))}
              </td>

              <td>
                <MdEdit
                  onClick={() => handleEditMessage(message)} // Open the modal for editing
                  style={{ cursor: 'pointer' }}
                  size='2em'
                />
                <MdDelete
                  onClick={() => setdeletingmessageid(message.id)}
                  style={{ cursor: 'pointer', color: 'red' }}
                  size='2em'
                />
                <HiOutlineDocumentDuplicate
                  onClick={() => handleduplicate({
                    message: message.message,
                    tags: JSON.parse(message.tags),
                    response: message.response,
                    options: JSON.parse(message.options),
                  })}
                  style={{ cursor: 'pointer', color: 'lightblue' }}
                  size='2em'
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {deletingmessageid && (
        <DeleteModal onDeleteConfirmed={() => handleDeletemessage(deletingmessageid)} statedeletbtn={() => setdeletingmessageid(null)} />
      )}
    </div>
  );
};

export default MessagesTable;




//  <div><strong>Label:</strong> {option.label}</div>
//       <div><strong>Type:</strong> {option.type}</div>
//       <div><strong>Value:</strong> {option.value}</div> 