// Register.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../Config";
import './register.css';
import { MdAdminPanelSettings } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { MdOutlineAdminPanelSettings } from "react-icons/md";



function Register({ userRole }) {
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");
  const [longnameReg, setlongnameReg] = useState("");
  const [usersdata, setUsersdata] = useState([]);
  const [role, setRole] = useState("sousadmin");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${url}/api/users/usersdata`, {
        headers: {
          'x-access-token': localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        if (userRole === 'supadmin') {
          setUsersdata(response.data.userdata);
        } else {
          setUsersdata(response.data.userdata.filter(user => user.role !== 'supadmin'));
        }

      } else {
        console.error('Error fetching users:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const deleteUser = (username, role) => {
    axios.delete(`${url}/api/users/delete`, {
      data: { username, role },
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    })
      .then((response) => {
        // console.log(response);
        fetchUsers();
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  };

  const register = () => {
    if (usernameReg === '' || passwordReg === '') {
      alert('fill user data !!!')
    } else {
      axios.post(`${url}/api/users/register`, {
        username: usernameReg,
        password: passwordReg,
        longname: longnameReg,
        role: role,
      }, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }).then((response) => {
        setPasswordReg('');
        setlongnameReg('')
        setUsernameReg('');
        setRole('');
        if (response.status === 200) {
          // alert("تم التسجيل بنجاح")
          fetchUsers();
        } else {
          if (response.data && response.data.error && response.data.error.includes("username")) {
            alert("Username already exists!");
          } else {
            alert("Username already exists! Registration failed. Please try again.");
          }
        }
      }).catch((error) => {
        console.error('Error registering user:', error);
        alert("An error occurred during registration. Please try again.");
      });
    }
  };



  return (
    <div className="registration-container">
      <div className="user-data-container">
        <div className="user-data-container">
          <table>
            {/* <thead>
              <tr>
                <th>Avatar</th>
                <th>Username</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead> */}
            <tbody>
              {usersdata && usersdata.map((user, i) => (
                <tr key={i}>
                  <td className="user-avatar">{user.role === 'sousadmin' ? <RiAdminFill /> : (user.role === 'admin' ? <MdAdminPanelSettings /> : <MdOutlineAdminPanelSettings />)}</td>
                  <td>{user.longname}</td>
                  <td>{user.username}</td>
                  <td>{user.role === 'sousadmin' ? 'نائب المدير' : (user.role === 'admin' ? 'المدير' : 'مدير عام')}</td>
                  <td>
                    {user.role !== 'supadmin' ? <button onClick={() => deleteUser(user.username, user.role)}>حدف</button> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
      <fieldset style={{ position: 'relative' }}>
        <legend style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}> <button onClick={register}>تسجيل</button></legend>
        <div className="registration-form">
          <input
            value={longnameReg}
            placeholder="الإسم الكامل بالعربية"
            type="text"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[ء-ي\s]+$/.test(inputValue) || inputValue === '') {
                setlongnameReg(inputValue);
              }              
            }}
          />

          <input
            value={usernameReg}
            placeholder="اسم المستخدم"
            type="text"
            onChange={(e) => {
              setUsernameReg(e.target.value);
            }}
          />
          <div className="form-group">
            <div className="custom-radio-group">
              <label className={`custom-radio ${role === 'admin' ? 'active' : ''}`}>
                <input
                  type="radio"
                  name="role"
                  value="admin"
                  onChange={() => setRole('admin')}
                  checked={role === 'admin'}
                />
                <MdAdminPanelSettings /> المدير
              </label>

              <label className={`custom-radio ${role === 'sousadmin' ? 'active' : ''}`}>
                <input
                  type="radio"
                  name="role"
                  value="sousadmin"
                  onChange={() => setRole('sousadmin')}
                  checked={role === 'sousadmin'}
                />
                <RiAdminFill /> نائب المدير
              </label>
            </div>
          </div>
          <input
            value={passwordReg}
            placeholder="كلمة المرور"
            type="password"
            onChange={(e) => {
              setPasswordReg(e.target.value);
            }}
          />
        </div>
      </fieldset>
    </div>
  );
}

export default Register;
