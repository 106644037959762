
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { url } from '../../Config';
import AddRow from './AddRow';
import EditRow from './EditRow';
import MessagesTable from './MessagesTable';
import Modal from '../../boxes/Modal';
import { MdOutlineAdd } from 'react-icons/md';
import Loading from '../../boxes/Loading';
import './mssg.css';


const Usermessages = ({ userRole }) => {
  const [ondelete, setondelete] = useState(false);
  const [messages, setMessages] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [editingMessage, setEditingMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const isAdmin = userRole === 'admin';
  const isSousadmin = userRole === 'sousadmin';
  const isSupAdmin = userRole === 'supadmin';


  useEffect(() => {
    fetchCategories();
    const categoryParam = searchParams.get('Category');
    const subcategoryParam = searchParams.get('subcategory');

    if (categoryParam) {
      setSelectedCategory(categoryParam);
    }
    if (subcategoryParam) {
      setSelectedSubcategory(subcategoryParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedCategory && selectedSubcategory) {
      fetchMessages(selectedCategory, selectedSubcategory);
      setSearchParams({ Category: selectedCategory, subcategory: selectedSubcategory });
    } else {
      setMessages(null);
      setSearchParams({});
    }
  }, [selectedCategory, selectedSubcategory]);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (ondelete === true ) {
       fetchMessages(selectedCategory, selectedSubcategory);
    }
  }, [ondelete]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false); // Close the editing modal
    setEditingMessage(null);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${url}/admin/categories/get-categories`, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        let categoadmin;

        if (isSupAdmin) {
          categoadmin = data.categories;
        } else {
          categoadmin = data.categories.filter(e => e.Category_id !== 1);
        }

        setCategories(categoadmin);
        setLoading(false);
      } else {
        console.error('Failed to fetch categories');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const fetchSubcategories = async () => {
    try {
      if (!isSupAdmin && selectedCategory === 'nlp_farid_secure') {
        setSubcategoryData([]);
        navigate('/invalid-category-page');
        return;
      }

      const response = await fetch(`${url}/admin/subcategories/subcategories-table/${selectedCategory}`, {
        method: 'GET',
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSubcategoryData(data.subcategories);
      } else {
        console.error('Failed to fetch subcategories');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchMessages = async (category, subcategory) => {
    try {
      if (!isSupAdmin && category === 'nlp_farid_secure') {
        setMessages(null);
        navigate('/invalid-category-page');
        return;
      }

      const response = await fetch(`${url}/admin/usermessages/messages/${category}/${subcategory}`, {
        method: 'GET',
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
      } else {
        navigate('/invalid-category-page');
        console.error('Error fetching messages:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };


  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);
    setSelectedSubcategory('');
    setSearchParams({ Category: selectedCategory });
  };

  const handleSubcategorySelect = (e) => {
    const selectedSubcategory = e.target.value;
    setSelectedSubcategory(selectedSubcategory);
    setSearchParams({ subcategory: selectedSubcategory });
  };


  const handleAddMessage = async (newMessage) => {
    try {
      const response = await fetch(`${url}/admin/usermessages/add/${selectedCategory}/${selectedSubcategory}`, {
        method: 'POST',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMessage),
      });

      if (response.ok) {
        await fetchMessages(selectedCategory, selectedSubcategory);
      } else {
        console.error('Error adding message:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding message:', error);
    }
  };
  
  const onDuplicate = async (newMessage) => {
    try {
      const response = await fetch(`${url}/admin/usermessages/add/${selectedCategory}/${selectedSubcategory}`, {
        method: 'POST',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMessage),
      });

      if (response.ok) {
        await fetchMessages(selectedCategory, selectedSubcategory);
      } else {
        console.error('Error adding message:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding message:', error);
    }
  };

  const handleEditMessage = (message) => {
    setEditingMessage(message);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleUpdateMessage = async (updatedMessage) => {
    try {
      const response = await fetch(`${url}/admin/usermessages/update-message/${selectedCategory}/${selectedSubcategory}/${updatedMessage.id}`, {
        method: 'PUT',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedMessage), // Pass the updated message object
      });

      if (response.ok) {
        // If the request was successful, fetch the updated list of messages
        await fetchMessages(selectedCategory, selectedSubcategory);
        setEditingMessage(null);
        closeModal() // Clear the editing message after update
      } else {
        // Handle the case when the request was not successful
        console.error('Error updating message:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating message:', error);
    }
  };

  return (
    <div className='mssg-container'>
      <h2>{selectedCategory}  /  {selectedSubcategory}</h2>
      <div>
        <select id='selectCategory' value={selectedCategory} onChange={handleCategorySelect}>
          <option value="">اختر فئة </option>
          {categories.map((category) => (
            <option key={category.Category_id} value={category.Category_name}>
              {category.Category_name}
            </option>
          ))}
        </select>
        <select id='selectCategory' value={selectedSubcategory} onChange={handleSubcategorySelect}>
          <option value="">اختر فئة فرعية</option>
          {subcategoryData.map((subcategory) => (
            <option key={subcategory.id} value={subcategory.subcategorie_name}>
              {subcategory.subcategorie_name}
            </option>
          ))}
        </select>
      </div>
      <MdOutlineAdd
        onClick={openModal}
        style={{ cursor: 'pointer', color: 'green' }}
        size='2em'
      />
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {isEditing ? (
          <EditRow
            messageToEdit={editingMessage}
            onUpdateMessage={handleUpdateMessage}
          />
        ) : (
          <AddRow onAddMessage={handleAddMessage} />
        )}
      </Modal>
      <div>
        {messages ? (
          <MessagesTable
            messages={messages}
            selectedCategory={selectedCategory}
            selectedSubcategory={selectedSubcategory}
            onEditMessage={handleEditMessage}
            onUpdateMessage={handleUpdateMessage}
            setondelete={setondelete}
            onDuplicate={onDuplicate}
          />
        ) : (
          <> <Loading /></>

        )}
      </div>
    </div>
  );
};

export default Usermessages;


