export const url='https://hello.kiliati.com'
// export const url = "http://localhost:3001";
// export const url='http://192.168.1.105:3001'

export const generatecleantags = (name) => {
  const excludedWords = [
    "وهي",
    "ان",
    "اذا",
    "في",
    "على",
    "ما",
    "هل",
    "كيف",
    "من",
    "لا",
    "بين",
    "هل",
    "أو",
    "غير",
    "أن",
    "إلى",
    "لماذا",
    "هي",
    "يجب",
    "كان",
    "عند",
    "قبل",
    "بعد",
    "أين",
    "الذي",
    "مع",
    "أيّ",
    "شيء",
    "كلّ",
    "بكثرة",
    "هذه",
    "هذا",
    "قليلاً",
    "فوق",
    "تحت",
    "جداً",
    "عادةً",
    "حتى",
    "مثل",
    "عليه",
    "به",
    "منذ",
    "إذا",
    "بدون",
    "حول",
    "أكثر",
    "كم",
    "و",
    "أو",
    "ثم",
    "بسبب",
    "لكن",
    "وقد",
    "فيما",
    "كثيراً",
    "إذ",
    "إذن",
    "عليها",
    "عليهم",
    "عليهن",
    "أولاً",
    "ثانياً",
    "بالتالي",
    "بالإضافة",
    "إليه",
    "إليها",
    "أيضاً",
    "كأن",
    "لديه",
    "لديها",
    "لكنّ",
    "بلا",
    "حيث",
    "بأن",
    "إنه",
    "إنّما",
    "كأنّه",
    "كأنّها",
    "بنا",
    "تلك",
    "تلكم",
    "تلكن",
    "ذلك",
    "ذلكم",
    "ذلكن",
    "هؤلاء",
    "ولكن",
    "مثله",
    "مثلهم",
    "مثلهن",
    "كأنّهم",
    "كأنّهن",
    "هنا",
    "هنالك",
    "هناك",
    "ومن",
    "هذين",
    "هاتان",
    "هذان",
    "ذان",
    "أيّان",
    "كيفما",
    "أينما",
    "بما",
    "أيضًا",
    "أولاء",
    "عل",
    "هٰذا",
    "هٰذه",
    "هيهات",
  ];
  
  const newname = name
    .replace(/_/g, " ")
    // .replace(/[!"#$%&'()*+,-.؟/:;<=>?@[\]^`{|}~]/g, "")
    .replace(/[a-zA-Z0-9!"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]/g, "")
    .trim();

    const execludemodif = excludedWords.map((word) =>
    word.replace(/[ًٌٍَُِّ\p{P}]/gu, "").trim()
  );

  const words = newname
    .split(/\s+/)
    .filter((word) => !execludemodif.includes(word));


  const modifiedWords = words.map((word) =>
    word.replace(/[ًٌٍَُِّ\p{P}]/gu, "").trim()
  );

  const uniqueWords = [...new Set(modifiedWords)];

  return uniqueWords.join("_");
};

export const formatInputString = (inputString) => {
  // Replace all characters that are not Arabic letters or Latin letters with underscores
  return inputString.replace(/[^A-Za-z\u0621-\u063A\u0641-\u064A]+/g, "_");
};

export const parseStyledText = (text) => {
  // Parse the text and return JSX with styles and URLs
  const boldRegex = /\*([\s\S]*?)\*/g;
  const italicRegex = /_([\s\S]*?)_/g;
  const underlineRegex = /~([\s\S]*?)~/g;
  const highlightRegex = /==([\s\S]*?)==/g;
  const redTextRegex = /@@red([\s\S]*?)@@/g;
  const greenTextRegex = /@@green([\s\S]*?)@@/g;
  const urlRegex = /\[([\s\S]*?)\]\(([\s\S]*?)\)/g;
  const ulRegex = /- ([\s\S]*?)\n/g;

  const replaceBold = '<span style="font-weight: bold;">$1</span>';
  const replaceItalic = '<span style="font-style: italic;">$1</span>';
  const replaceUnderline =
    '<span style="text-decoration: underline;">$1</span>';
  const replaceHighlight = "<mark>$1</mark>";
  const replaceRedText = '<span style="color: red;">$1</span>';
  const replaceGreenText = '<span style="color: green;">$1</span>';
  const replaceUrl =
    '<a href="$2"  style="color: blue; text-decoration: underline;"  target="_blank">$1</a>';
  const replaceUl =
    '<ul style="margin-top: 0 !important; margin-bottom: 0 !important; padding-top: 0 !important; padding-bottom: 0 !important;"><li style="margin: 0; padding: 0;">$1</li></ul>';

  return text
    .replace(boldRegex, replaceBold)
    .replace(italicRegex, replaceItalic)
    .replace(underlineRegex, replaceUnderline)
    .replace(highlightRegex, replaceHighlight)
    .replace(redTextRegex, replaceRedText)
    .replace(greenTextRegex, replaceGreenText)
    .replace(urlRegex, replaceUrl)
    .replace(ulRegex, replaceUl);
};





  // const excludedWords = [
  //   "ان",
  //   "في",
  //   "على",
  //   "ما",
  //   "هل",
  //   "كيف",
  //   "من",
  //   "لا",
  //   "بين",
  //   "هل",
  //   "أو",
  //   "غير",
  //   "أن",
  //   "إلى",
  //   "لماذا",
  //   "هي",
  //   "يجب",
  //   "كان",
  //   "عند",
  //   "قبل",
  //   "بعد",
  //   "أين",
  //   "الذي",
  //   "مع",
  //   "أيّ",
  //   "شيء",
  //   "كلّ",
  //   "بكثرة",
  //   "هذه",
  //   "هذا",
  //   "قليلاً",
  // ];