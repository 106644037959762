import React from 'react';
import './loading.css';

function Loading() {
  return (
    <div className="loading-card">
      <div className="loading-image"></div>
      <div className="loading-content">
        <div className="loading-title"></div>
        <div className="loading-description"></div>
      </div>
    </div>
  );
}

export default Loading;
