import React from 'react';
import { BiSolidDirections } from 'react-icons/bi';
import './Forbidden.css'; // Assuming you named the CSS file "Forbidden.css"

function Forbidden() {
  return (
    <div className="container-Forbidden">
      <div className="icon">
        <BiSolidDirections size="30dvw" color="#7eb0c49d" />
      </div>
      <div className="messageoops">Ooooops!</div>
    </div>
  );
}

export default Forbidden;