
import React, { useEffect, useState } from "react";
import axios from 'axios';
import './App.css';
import Login from "../Login/Login";
import Register from "../Login/Register";
import Dashboard from "../Panel/Dashboard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Categories from "../DashboardItems/categories/Categories";
import Fileupload from "../DashboardItems/Fileupload";
import Socialmessages from "../DashboardItems/Socialmessages/Socialmessages";
import Subcategories from "../DashboardItems/subCategories/Subcategories";
import Usermessages from "../DashboardItems/usermessages/Usermessages";
import { url } from "../Config";
import Question from "../DashboardItems/noresponse/Question"
import Quotes from "../DashboardItems/charts/Quotes";
import Forbidden from "../DashboardItems/Forbidden";

function App() {
  const [loginStatus, setLoginStatus] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [longname, setlongname] = useState('');

  useEffect(() => {
    // Check local storage for the token on component mount
    const token = localStorage.getItem("token");

    if (token) {
      // If a token is found, set the authentication status
      setLoginStatus(true);
        
      // Decode the token to get the user role
      decodeToken(token);
    }
  }, []);

  const decodeToken = async (token) => {
    try {
      const response = await axios.post(`${url}/api/users/decode/${token}`);
      if (response.status === 200) {
        const decodedToken = response.data;
        const decodedUserRole = decodedToken.userRole;
        const decodedlongname = decodedToken.longname;

        // Set the user role in the state
        setUserRole(decodedUserRole);
        setlongname(decodedlongname)
      } else {
        // Handle the case where decoding fails
        setLoginStatus(false);
        setUserRole('');
        setlongname('')
      }
    } catch (error) {
      // Handle any errors that occur during the decoding process
      console.error('Error decoding token:', error);
      setLoginStatus(false);
      setUserRole('');
      setlongname('')
    }
  };

  const handleLogin = async (token) => {
    // Store the token in local storage
    localStorage.setItem("token", token);

    // console.log('toooooook', token);

    // Decode the token to get the user role
    decodeToken(token);
  };

  const handleLogout = () => {
    // Remove the token and user role from local storage on logout
    localStorage.removeItem("token");
    setLoginStatus(false);
    setUserRole('');
    setlongname('')
  };
  return (
    <div className="App">
      {loginStatus ? (
        <BrowserRouter>
          <Dashboard userRole={userRole} handleLogout={handleLogout}  longname={longname} >
            <Routes>
              <Route  path="Fileupload" element={<Fileupload />} />
              <Route  path="categories" element={<Categories />} />
              <Route path="subcategories" element={<Subcategories />} />
              <Route path="Register" element={<Register />} />
              <Route path="Usermessages" element={<Usermessages />} />
              <Route path="Question" element={<Question />} />
              <Route path="Socialmessages" element={<Socialmessages />} />
              <Route index element={<Quotes />} />
              <Route path="*" element={<Forbidden />} />
            </Routes>
          </Dashboard>
        </BrowserRouter>
      ) : (
        <Login handleLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;




// {loginStatus ? (
//   return (
//      <BrowserRouter>
//        <div className="App">
//            <Dashboard userRole={userRole} handleLogout={handleLogout}>
//              <Routes>
//                <Route index path="categories" element={<Categories />} />
//                <Route path="subcategories" element={<Subcategories />} />
//                <Route path="Register" element={<Register />} />
//                <Route path="Usermessages" element={<Usermessages />} />
//                <Route path="Socialmessages" element={<Socialmessages />} />
//              </Routes>
//            </Dashboard>
//        </div>
//      </BrowserRouter>
//    );
//  :
//    return (
//    <div className="App">
//            <Login handleLogin={handleLogin} />
//    </div>)
//       )}
 