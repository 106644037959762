import React, { useState, useEffect } from 'react';
import { url, formatInputString ,generatecleantags} from '../../Config';
import Modal from '../../boxes/Modal';
import { MdDelete, MdEdit, MdOutlineAdd } from 'react-icons/md';
import axios from 'axios';
import './norepsocial.css'


const Socialmessages = ({ Norepmssg, Norepmssgtags, ondelete }) => {

  const [tablename, settablename] = useState(undefined);
  const [response, setresponse] = useState('')
  const [newresponse, setnewresponse] = useState({
    response: [],
    tags: Norepmssgtags,
  });

  const [newTag, setNewTag] = useState('');




  const handleAddResponseadd = () => {
    if (response.trim() !== '') {
      setnewresponse({
        ...newresponse,
        response: [...newresponse.response, response],
      });
    }
    setresponse('')
  };

  const handleRemoveResponseadd = (index) => {
    const updatedResponses = [...newresponse.response];
    updatedResponses.splice(index, 1);
    setnewresponse({
      ...newresponse,
      response: updatedResponses,
    });
  };






  const handleAddClick = async () => {
    if (newresponse.response.length === 0 || tablename === undefined || tablename === '' || tablename === null) {
      alert('Message cannot be empty and category.');
      return;
    }

    try {
      const response = await axios.post(`${url}/admin/usermessages/social/add/${tablename}`, newresponse, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      });
      // console.log('add', response, response.status)
      if (response.status === 201) {

        await ondelete(Norepmssg.id)
        // alert('message added')
      } else {
        // Handle the case when the request was not successful
        console.error('Error adding response:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding response:', error);
    }

    setnewresponse({
      response: [],
      tags: [],
    });
    setNewTag('');
  };





  // --------------------------------------

  function normalizeArabic(text) {
    return text
      .replace(/[أإآ]/g, 'ا')
      // .replace(/ة/g, 'ه')    
      // .replace(/ى/g, 'ي') 
      .replace(/[ًٌٍَُّ]/g, '');
  }
  const handleAddTag = () => {
    if (newTag.trim() !== '') {
      setnewresponse({ ...newresponse, tags: [...newresponse.tags, formatInputString(normalizeArabic(generatecleantags(newTag)))] });
      setNewTag('');
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewresponse({ ...newresponse, [name]: value });
  };

  const handleTagInputChange = (e) => {
    setNewTag(normalizeArabic(e.target.value));
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...newresponse.tags];
    updatedTags.splice(index, 1);
    setnewresponse({ ...newresponse, tags: updatedTags });
  };




  return (
    <div className='mssg-container'>
      {/* <h2>Messages Table: {tablename}</h2> */}

      <div>
        <select className='selectCategory' value={tablename} onChange={e => settablename(e.target.value)}>
          <option value="">---حدد النوع---</option>
          <option value='WelcomeMessages'>قائمة الترحيب </option>
          <option value='thankyoumessages'>قائمة الشكر</option>
          <option value='apologymessages'> قائمة الاعتذار</option>
          <option value='Helpmessages'>قائمة المساعدة </option>
        </select>

        {tablename === 'WelcomeMessages' ? <h2 style={{ margin: '0px' }}> قائمة الترحيب</h2> : ''}
        {tablename === 'thankyoumessages' ? <h2 style={{ margin: '0px' }}>قائمة الشكر</h2> : ''}
        {tablename === 'apologymessages' ? <h2 style={{ margin: '0px' }}> قائمة الاعتذار</h2> : ''}
        {tablename === 'Helpmessages' ? <h2 style={{ margin: '0px' }}>قائمة المساعدة </h2> : ''}
      </div>
      {tablename !== undefined && tablename !== '' && tablename !== null ?
        (
          <>
      <div>
        <MdOutlineAdd
          onClick={handleAddClick}
          style={{ cursor: 'pointer', color: 'green', margin: '0', position: 'absolute', top: '20px', right: '9px' }}
          size='2em'
          id='btn-add'
        />
      </div>
      <div>
        <p>"{Norepmssg.message}"</p>
        <div>
          <hr></hr>
          <input
            style={{ width: '70%', marginLeft: '5px' }}
            type="text"
            name="response"
            placeholder="الإجابة"
            value={response}
            onChange={(e) => setresponse(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddResponseadd();
              }
            }}
          />
          <button onClick={handleAddResponseadd}>
            +
          </button>
          <div className='boxresponses'>
            {/* <h3>Responses</h3> */}
            {newresponse.response.map((response, index) => (
              <div key={index} className='responseitem' >
                {response}
                <button onClick={() => handleRemoveResponseadd(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
              </div>
            ))}
          </div>
          <hr></hr>
          <div>
            <input
              style={{ width: '70%', marginLeft: '5px' }}
              type="text"
              name="tags"
              placeholder="Tags"
              value={newTag}
              onChange={handleTagInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddTag();
                }
              }}
            />
            <button onClick={handleAddTag}>+</button>
          </div>
          <div>
            {/* <h3>Tags</h3> */}
            <ul className='uledit'>
              {newresponse.tags.map((tag, index) => (
                <li className='liedit' key={index} style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
                  {tag}
                  <button onClick={() => handleRemoveTag(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
                </li>
              ))}
            </ul>
          </div>
          {/* <hr></hr> */}
        </div>
      </div>
      </>
        ) : null}

    </div>
  )
}

export default Socialmessages


