import React, { useState } from 'react';
import axios from 'axios';
import { MdOutlineAdd, MdUpload } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri'
import { url } from '../Config';
import './ScrollAnimation.css'

function Fileupload() {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Add the file to the form data
    formData.append('fileType', file.type); // Add the file type

    try {
      const response = await axios.post(`${url}/admin/usermessages/import-excel`, formData, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file upload
        },
      });
      // console.log(response, response.status)
      if (response.status === 201) {
        alert('File uploaded successfully.');
        setFile(null);
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
      alert('Failed to upload the file.');
    }
  };

  const downloadCanvaFile = () => {
    // Define the file path to your "canva.xlsx" in the public directory
    const canvaFilePath = '/img/canva-data.xlsx';

    // Create an anchor element for downloading
    const downloadLink = document.createElement('a');
    downloadLink.href = canvaFilePath;

    // Set the download attribute to indicate it should be downloaded
    downloadLink.download = 'canva.xlsx';

    // Trigger a click event to start the download
    downloadLink.click();
  };

  return (
    <div>
    <h1>تحميل ملف البيانات</h1>
    {/* <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} /> */}
    <div className="file-input-container">
      <label className="file-label" htmlFor="file-input">
        اختر ملف
      </label>
      <input type="file" accept=".xlsx, .xls" id="file-input" onChange={handleFileChange} style={{ display: "none" }} />
    </div>
    <div>
      <MdUpload
        onClick={handleUpload}
        style={{ cursor: 'pointer', color: 'green' }}
        size="2em"
      />
    </div>
    <p style={{ fontSize: '14px', color: 'gray' }}>
      يجب أن يكون الملف الذي تم تحميله ملفًا إكسل  'Excel' ويجب أن يتوافق مع تنسيق محدد.
    </p>
    <p style={{ fontSize: '14px', color: 'green', marginTop: '100px', fontStyle: 'italic' }}>👇🏻 قم بتنزيل ملف Canva Excel 👇🏻</p>
    <RiFileExcel2Fill
      onClick={downloadCanvaFile}
      style={{ cursor: 'pointer', color: 'green' }}
      size="2em"
    />
  </div>  
  );
}

export default Fileupload;
