import React, { useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";
import { FaUserCheck, FaUserTimes } from "react-icons/fa";
import { url } from "../../Config";
import axios from "axios";
import "./question.css";

export default function Question({ userRole }) {
  const [withResponse, setwithResponse] = useState(0);
  const [noResponse, setnoResponse] = useState(0);
  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${url}/admin/norep/counter`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setwithResponse(data.withResponse);
        setnoResponse(data.noResponse);
      } else {
        // console.error(
        //   "Error fetching messages:",
        //   response.status,
        //   response.statusText
        // );
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  return (
    <div className="question-container">
      <Link to="/Question/Noresponse" className="question-card">
        <div className="icon-wrapper">
          <FaUserTimes className="icon" />
        </div>
        <p className="p-wrapper">{noResponse}</p>
        <div className="card-label">بــــدون رد</div>
      </Link>

      <Link to="/Question/Withresponse" className="question-card">
        <div className="icon-wrapper">
          <FaUserCheck className="icon" />
        </div>
        <p className="p-wrapper">{withResponse}</p>
        <div className="card-label">بـــــــرد</div>
      </Link>
    </div>
  );
}
