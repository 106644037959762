import React, { useState, useEffect } from 'react';
import { url, formatInputString , generatecleantags } from '../../Config';
import Modal from '../../boxes/Modal';
import { MdDelete, MdEdit, MdOutlineAdd } from 'react-icons/md';
import './socialmssggg.css';
import axios from 'axios';
import { LuFileJson } from "react-icons/lu";
import { CiExport } from "react-icons/ci";




const Socialmessages = () => {
  const [tablename, settablename] = useState(undefined);
  const [messages, setMessages] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [response, setresponse] = useState('')
  const [newresponse, setnewresponse] = useState({
    response: [],
    tags: [],
  });
  const [editMessage, setEditMessage] = useState({
    id: null,
    response: [],
    tags: [],
  });
  const [newTag, setNewTag] = useState(''); // State for a new tag
  const [editTagInput, setEditTagInput] = useState('');
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    if (tablename !== '') {
      fetchMessages();
    } else {
      setMessages(undefined)
    }
  }, [tablename]);


  const handleAddResponseadd = () => {
    if (response.trim() !== '') {
      setnewresponse({
        ...newresponse,
        response: [...newresponse.response, response],
      });
    }
    setresponse('');
  };

  const handleAddResponse = () => {
    if (response.trim() !== '') {
      setEditMessage({
        ...editMessage,
        response: [...editMessage.response, response],
      });
    }
    setresponse('');
  };

  const handleRemoveResponse = (index) => {
    const updatedResponses = [...editMessage.response];
    updatedResponses.splice(index, 1);
    setEditMessage({
      ...editMessage,
      response: updatedResponses,
    });
  };
  const handleRemoveResponseadd = (index) => {
    const updatedResponses = [...newresponse.response];
    updatedResponses.splice(index, 1);
    setnewresponse({
      ...newresponse,
      response: updatedResponses,
    });
  };

  const openEditModal = (message) => {
    // console.log(message)
    // console.log(message.tags)
    // console.log(JSON.parse(message.tags).map(e=> e))
    // Convert message.tags to an array if it's not already
    // const tagsArray = Array.isArray(message.tags)
    //   ? message.tags
    //   : typeof message.tags === 'string'
    //     ? message.tags.split(',') 
    //     : []; 

    // Initialize the edit message state with the data of the selected message
    setEditMessage({
      id: message.id,
      response: message.response,
      tags: message.tags, // Use the converted array
    });
    setIsEditModalOpen(true); // Open the edit modal
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setresponse('');
  };

  const handleEditTagInputChange = (e) => {
    // Update the tags in the editMessage state as an array of strings without brackets and quotes
    const tagsArray = e.target.value
      .split(',')
      .map((tag) => tag.trim().replace(/["\[\]]/g, ''));

    setEditMessage({
      ...editMessage,
      tags: tagsArray, // Store tags as an array
    });
  };



  const handleRemoveEditTag = (index) => {
    const updatedTags = [...editMessage.tags];
    updatedTags.splice(index, 1);
    setEditMessage({
      ...editMessage,
      tags: updatedTags,
    });
  };



  const handleEditResponseInputChange = (e) => {
    // Update the response in the editMessage state
    setEditMessage({
      ...editMessage,
      response: e.target.value,
    });
  };

  const handleUpdateClick = async () => {
    // console.log('editMessage', editMessage);
    try {
      const response = await axios.put(`${url}/admin/usermessages/social/update/${tablename}/${editMessage.id}`, {
        response: editMessage.response,
        tags: editMessage.tags, // Send tags as an array
      }, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      });
      // console.log('update', response, response.status)
      if (response.status === 200) {
        // If the request was successful, fetch the updated list of messages
        closeEditModal();
        await fetchMessages();
      } else {
        // Handle the case when the request was not successful
        console.error('Error updating response:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating response:', error);
    }
  };

  const handleAddClick = async () => {
    if (newresponse.response.length === 0) {
      alert('Message cannot be empty.');
      return;
    }

    try {
      const response = await axios.post(`${url}/admin/usermessages/social/add/${tablename}`, newresponse, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      });
      // console.log('add', response, response.status)
      if (response.status === 201) {
        closeModal();
        // If the request was successful, fetch the updated list of messages
        await fetchMessages();
      } else {
        // Handle the case when the request was not successful
        console.error('Error adding response:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding response:', error);
    }

    setnewresponse({
      response: [],
      tags: [],
    });
    setNewTag('');
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${url}/admin/usermessages/social/${tablename}`, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      // console.log('fetch', response, response.status)
      if (response.status === 200) {
        const data = response.data;
        setMessages(data.messages);
        // console.log(data);
      } else {
        console.error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const downloadJsonFile = (data, filename) => {
    const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(jsonBlob);
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const uploadSocialMessageJson = async () => {
    try {
      const response = await axios.get(`${url}/admin/usermessages/uploadSocialMessageJson`, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const data = response.data.data;
        downloadJsonFile(data, 'socialMessages.json');
      } else {
        console.error('Failed to fetch messages. Server returned:', response.status, response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const importSocialMessageJson = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }
  
    try {
      const reader = new FileReader();
      
      reader.onload = async (event) => {
        const content = event.target.result;
  
        try {
          const response = await axios.post(`${url}/admin/usermessages/importSocialMessageJson`, {
            data: JSON.parse(content),
          }, {
            headers: {
              'x-access-token': localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          });
  
          if (response.status === 200) {
            setFile(null)
            alert('JSON data successfully uploaded:');
          } else {
            console.error('Failed to upload JSON data. Server returned:', response.status, response.data);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
  
      // Read the content of the file
      reader.readAsText(file);
    } catch (error) {
      console.error('Error reading file:', error);
    }
  };
  

  const handleDeletemessage = async (id) => {
    try {
      const response = await axios.delete(`${url}/admin/usermessages/social/delete-messg/${tablename}/${id}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      });
      // console.log('del', response, response.status)
      if (response.status === 200) {
        // If the request was successful, fetch the updated list of messages
        await fetchMessages();
      } else {
        // Handle the case when the request was not successful
        console.error('Error deleting response:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  // --------------------------------------
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setresponse('');
  };

  function normalizeArabic(text) {
    return text
      .replace(/[أإآ]/g, 'ا')
      // .replace(/ة/g, 'ه')    
      // .replace(/ى/g, 'ي') 
      .replace(/[ًٌٍَُّ]/g, '');
  }
  const handleAddTag = () => {
    if (newTag.trim() !== '') {
      setnewresponse({ ...newresponse, tags: [...newresponse.tags, formatInputString(normalizeArabic(generatecleantags(newTag)))] });
      setNewTag('');
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewresponse({ ...newresponse, [name]: value });
  };

  const handleTagInputChange = (e) => {
    setNewTag(normalizeArabic(e.target.value));
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...newresponse.tags];
    updatedTags.splice(index, 1);
    setnewresponse({ ...newresponse, tags: updatedTags });
  };



  // Function to get a sequential index for a message's responses
  const getSequentialIndex = (messageId) => {
    const currentIndex = randomIndices[messageId] || 0;
    const responseLength = messages.find((message) => message.id === messageId)?.response.length || 0;
    const nextIndex = (currentIndex + 1) % responseLength; // Cycle back to 0 when reaching the last index
    return nextIndex;
  };

  // State to track the sequential index for each row
  const [randomIndices, setRandomIndices] = useState({});

  // Function to change the sequential index for a specific row
  const changeSequentialIndex = (messageId) => {
    setRandomIndices((prevIndices) => ({
      ...prevIndices,
      [messageId]: getSequentialIndex(messageId),
    }));
  };

  return (
    <div className='mssg-container'>
      <div id='json-charge'>
        <CiExport
          onClick={importSocialMessageJson}
          style={{ cursor: 'pointer', color: 'green', marginTop: '10px' }}
          size='2em'
        />
        <div className="filee-input-container">
          <label className="file-label" htmlFor="file-input">
            اختر ملف
          </label>
          <input type="file" accept=".json" id="file-input" onChange={handleFileChange} style={{ display: "none" }} />
        </div>
      </div>
      {/* <h2>Messages Table: {tablename}</h2> */}
      <div>
        <select value={tablename} onChange={e => settablename(e.target.value)}>
          <option value="">---حدد النوع---</option>
          <option value='WelcomeMessages'>قائمة الترحيب </option>
          <option value='thankyoumessages'>قائمة الشكر</option>
          <option value='apologymessages'> قائمة الاعتذار</option>
          <option value='Helpmessages'>قائمة المساعدة </option>
        </select>
      </div>
      {tablename !== undefined && tablename !== '' && tablename !== null ?
        (<MdOutlineAdd
          onClick={openModal}
          style={{ cursor: 'pointer', color: 'green', marginTop: '10px' }}
          size='2em'
        />) : null
      }

      {tablename === 'WelcomeMessages' ? <h2 style={{ margin: '0px' }}> قائمة الترحيب</h2> : ''}
      {tablename === 'thankyoumessages' ? <h2 style={{ margin: '0px' }}>قائمة الشكر</h2> : ''}
      {tablename === 'apologymessages' ? <h2 style={{ margin: '0px' }}> قائمة الاعتذار</h2> : ''}
      {tablename === 'Helpmessages' ? <h2 style={{ margin: '0px' }}>قائمة المساعدة </h2> : ''}
      <q style={{ marginBottom: '8px', display: 'block', fontStyle: 'italic', color: 'red' }}>يرجى تقديم ردود مناسبة ومقنعة لكلمة المستخدم لتحقيق إجابة مقنعة.</q>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div>
          {/* <h2>Add New Message</h2> */}
          <div>
            {/* <hr></hr> */}
            <input
              style={{ width: '70%', marginLeft: '5px' }}
              type="text"
              name="response"
              placeholder="الإجابة"
              value={response}
              onChange={(e) => setresponse(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                  handleAddResponseadd(); // Add the response
                }
              }}
            />
            <button onClick={handleAddResponseadd}>
              +
            </button>
            <div className='boxresponses'>
              {/* <h3>Responses</h3> */}
              {newresponse.response.map((response, index) => (
                <div key={index} className='responseitem'>
                  {response}
                  <button onClick={() => handleRemoveResponseadd(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
                </div>
              ))}
            </div>
            <hr></hr>
            <div>
              <input
                style={{ width: '70%', marginLeft: '5px' }}
                type="text"
                name="tags"
                placeholder="الوسوم"
                value={newTag}
                onChange={handleTagInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddTag();
                  }
                }}
              />
              <button onClick={handleAddTag}>+</button>
            </div>

            <ul className='uledit'>
              {/* <h3>Tags</h3> */}
              {newresponse.tags.map((tag, index) => (
                <li className='liedit' key={index} style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
                  {tag}
                  <button onClick={() => handleRemoveTag(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
                </li>
              ))}
            </ul>
            {/* <hr></hr> */}

            <div>
              <MdOutlineAdd
                onClick={handleAddClick}
                style={{ cursor: 'pointer', color: 'green', margin: '0', position: 'absolute', top: '20px', right: '9px' }}
                size='2em'
                id='btn-add'
              />
              {/* <button onClick={handleAddClick}  style={{ cursor: 'pointer', color: 'green', margin: '0', position: 'absolute', top: '20px', right: '9px' }}>Add</button> */}
            </div>
          </div>
        </div>
      </Modal>
      <div id='tblscrl'>
        <table className='custom-tabl'>
          <thead>
            <tr>
              <th>ID</th>
              <th>رد البوت 🤖</th>
              <th>كلمات مستخرجة من رسالة المستخدم 💁🏻</th>
              <th>
                <LuFileJson
                  onClick={uploadSocialMessageJson}
                  style={{ cursor: 'pointer', color: 'green', marginTop: '10px' }}
                  size='2em'
                />

              </th>
            </tr>
          </thead>
          <tbody>
            {messages?.map((message) => (
              <tr key={message.id}>
                <td>{message.id}</td>
                {/* <td>{message.response[getRandomIndex(message)]}</td> */}
                <td
                  onClick={() => changeSequentialIndex(message.id)}
                  style={{ cursor: 'pointer' }}
                >
                  {message.response[randomIndices[message.id] || 0]}
                </td>
                <td>
                  <ul className='custom-list'>
                    {message.tags.map((tag, index) => (
                      <li key={index}>#{tag}</li>
                    ))}
                  </ul>
                </td>
                <td id='actio'>
                  <MdEdit
                    onClick={() => openEditModal(message)}
                    style={{ cursor: 'pointer' }}
                    size='2em'
                  />
                  <MdDelete
                    onClick={() => handleDeletemessage(message.id)}
                    style={{ cursor: 'pointer', color: 'red' }}
                    size='2em'
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <div>
          {/* <h2>Edit Message</h2> */}
          <div>
            {/* <hr></hr> */}
            <div>
              <input
                style={{ width: '70%', marginLeft: '5px' }}
                type="text"
                name="response"
                placeholder="الإجابة"
                value={response}
                onChange={(e) => setresponse(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent form submission
                    handleAddResponse(); // Add the response
                  }
                }}
              />
              <button onClick={handleAddResponse}>
                +
              </button>
            </div>
            <div className='boxresponses'>
              {/* <h3>Responses</h3> */}
              {editMessage.response.map((response, index) => (
                <div key={index} className='responseitem'>
                  {response}
                  <button onClick={() => handleRemoveResponse(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
                </div>
              ))}
            </div>
            <hr></hr>
            <input
              style={{ width: '70%', marginLeft: '5px' }}
              type="text"
              name="tags"
              placeholder="الوسوم"
              value={editTagInput}
              onChange={(e) => setEditTagInput(formatInputString(normalizeArabic(generatecleantags(e.target.value))))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                  if (e.target.value.trim() !== '') {
                    // Check if the input is not empty
                    setEditMessage({
                      ...editMessage,
                      tags: [...editMessage.tags, e.target.value.trim()], // Add the new tag
                    });
                    setEditTagInput(''); // Clear the input field
                  }
                }
              }}
            />
            <ul className='uledit'>
              {/* <h3>Tags</h3> */}
              {editMessage.tags.map((tag, index) => (
                <li className='liedit' key={index} style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
                  {tag}
                  <button onClick={() => handleRemoveEditTag(index)} style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} >X</button>
                </li>
              ))}
            </ul>

            {/* <hr></hr> */}
            <button onClick={handleUpdateClick} style={{ cursor: 'pointer', color: 'green', margin: '0', position: 'absolute', top: '20px', right: '9px' }} >حفض</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Socialmessages


