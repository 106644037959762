import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUserCheck, FaUserTimes } from "react-icons/fa";
import { Chart, Doughnut, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { url } from "../../Config";
import "./chart.css";
import Greeting from "./Greeting";

const Quotes = ({ longname }) => {
  const [withResponse, setWithResponse] = useState(0);
  const [noResponse, setNoResponse] = useState(0);
  const [categonum, setcategonum] = useState(0);
  const [datainserted, setdatainserted] = useState(0);

  useEffect(() => {
    fetchdatainserted();
    fetchcategonum();
    fetchMessages();
  }, []);

  const fetchcategonum = async () => {
    try {
      const response = await fetch(`${url}/admin/categories/countcatego`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setcategonum(data.modifiedCount);
      } else {
        console.error(
          "Error fetching catego:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  const fetchdatainserted = async () => {
    try {
      const response = await fetch(
        `${url}/admin/categories/countdatainserted`,
        {
          method: "GET",
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setdatainserted(data.totalRowCount);
      } else {
        console.error(
          "Error fetching catego:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${url}/admin/norep/counter`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const data = await response.json();
        setWithResponse(data.withResponse);
        setNoResponse(data.noResponse);
      } else {
        console.error(
          "Error fetching messages:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const chartData = {
    labels: [`${withResponse} بـــــــــــرد`, `${noResponse} بدون رد`],
    datasets: [
      {
        data: [withResponse, noResponse],
        backgroundColor: ["#80a1d0", "#578b9e"],
        hoverBackgroundColor: ["#80a1d0cc", "#578b9e"],
      },
    ],
  };

  return (
    <div className="dash-container">
      {/* <div className="div1"></div> */}
      <Link
        to="/categories"
        className="div4"
        style={{ textDecoration: "none" }}
      >
        {" "}
        <div>
          <h1 style={{ color: "#03506C", userSelect: "none" }}>عدد الفئات</h1>{" "}
          <br></br>
          <span
            style={{ fontSize: "100px", color: "#03506C", userSelect: "none" }}
          >
            {categonum}
          </span>
        </div>
      </Link>
      <Link
        to="/Usermessages"
        className="div3"
        style={{ textDecoration: "none" }}
      >
        <div>
          <h1 style={{ color: "#03506C", userSelect: "none" }}>
            إجمالي البيانات المدخلة
          </h1>{" "}
          <br></br>
          <span
            style={{ fontSize: "100px", color: "#03506C", userSelect: "none" }}
          >
            {datainserted}
          </span>
        </div>
      </Link>
      <Link to="/Question" className="div2" style={{ textDecoration: "none" }}>
        {" "}
        <div>
          {" "}
          <div className="chart-container">
            <Doughnut
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                    labels: {
                      color:'#03506C',
                      font: {
                        size: 33, 
                        weight:'bolder'
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </Link>
      {/* <div className="div5">--</div> */}
      {/* <div className="div6"> <span style={{fontSize:'50px'}}>{longname}</span> </div> */}
      <div className="div7">
        <Greeting longname={longname} />
      </div>
    </div>
  );
};

export default Quotes;

// DELIMITER //

// CREATE PROCEDURE count_rows_in_tables()
// BEGIN
//     DECLARE done INT DEFAULT FALSE;
//     DECLARE tbl_name VARCHAR(255);
//     DECLARE cur_tables CURSOR FOR
//         SELECT table_name
//         FROM information_schema.tables
//         WHERE table_schema = 'bot'
//             AND table_name LIKE '%\_subof\_%' ESCAPE '\\';

//     DECLARE CONTINUE HANDLER FOR NOT FOUND SET done = TRUE;

//     -- Temporary table to store row counts
//     CREATE TEMPORARY TABLE IF NOT EXISTS temp_row_counts (
//         table_name VARCHAR(255),
//         row_count INT
//     );

//     -- Open cursor and loop through tables
//     OPEN cur_tables;

//     read_loop: LOOP
//         FETCH cur_tables INTO tbl_name;
//         IF done THEN
//             LEAVE read_loop;
//         END IF;

//         -- Construct SQL to count rows in current table
//         SET @sql = CONCAT('INSERT INTO temp_row_counts (table_name, row_count) ',
//                           'SELECT ''', tbl_name, ''' AS table_name, COUNT(*) AS row_count ',
//                           'FROM ', tbl_name);

//         -- Execute dynamic SQL to count rows and insert into temp table
//         PREPARE stmt FROM @sql;
//         EXECUTE stmt;
//         DEALLOCATE PREPARE stmt;
//     END LOOP;

//     -- Close cursor
//     CLOSE cur_tables;

//     -- Calculate total row count
//     SELECT SUM(row_count) AS total_row_count
//     FROM temp_row_counts;

//     -- Clean up temp table
//     DROP TEMPORARY TABLE IF EXISTS temp_row_counts;
// END//

// DELIMITER ;

// CALL count_rows_in_tables();
