

import React, { useState } from 'react';
import { url, formatInputString } from '../../Config';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import './editcatego.css';

const Editcategory = ({ category, onSave }) => {
  const [newCategoryName, setNewCategoryName] = useState(category.Category_name);
  const [newTags, setNewTags] = useState(JSON.parse(category.Category_tags || '[]'));
  const [newTag, setNewTag] = useState('');
  const [type, setType] = useState(category.type); // Initialize with the existing 'type' value

  const handleSaveCategory = async (updatedCategory) => {
    try {
      const response = await fetch(`${url}/admin/categories/${updatedCategory.Category_id}`, {
        method: 'PUT',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedCategory),
      });

      if (response.ok) {
        // console.log('Category updated successfully');
        // Refresh the categories data or perform any necessary actions after a successful update
      } else {
        console.error('Failed to update category');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSave = () => {
    // Check if the newCategoryName is empty before saving
    if (newCategoryName.trim() === '') {
      console.error('Category name cannot be empty');
      return;
    }

    const updatedCategory = {
      ...category,
      Category_name: newCategoryName.trim(),
      Category_tags: JSON.stringify(newTags),
      type: type, // Include the 'type' property in the updated category
    };

    // Call handleSaveCategory to send the updated category data to the backend
    handleSaveCategory(updatedCategory);

    // Call onSave to update the parent component with the edited category data (optional)
    onSave(updatedCategory);
  };

  const handleAddTag = () => {
    if (newTag.trim() !== '') {
      setNewTags([...newTags, formatInputString(normalizeArabic(newTag))]);
      setNewTag('');
    }
  };

  function normalizeArabic(text) {
    return text
      .replace(/[أإآ]/g, 'ا')
      // .replace(/ة/g, 'ه')
      // .replace(/ى/g, 'ي')
      .replace(/[ًٌٍَُّ]/g, '');
  }

  const handleRemoveTag = (index) => {
    const updatedTags = [...newTags];
    updatedTags.splice(index, 1);
    setNewTags(updatedTags);
  };

  return (
    <div>
      <button style={{position:'absolute',top:'20px' , right:'9px'}} onClick={handleSave}>حفض</button>
      {/* <h2>Edit Category</h2> */}
      {/* <label>Category Name:</label> */}
      <input type="text" value={newCategoryName.replaceAll('_', ' ')} onChange={(e) => setNewCategoryName(formatInputString(e.target.value))} />

      <div className="form-group">
        {/* <label>اختر النوع:</label> */}
        <div className="custom-radio-group">
          <label className={`custom-radio ${type === 1 ? 'active' : ''}`} onClick={() => setType(1)}>
            <input type="radio" value={1} checked={type === 1} onChange={() => { }} />
            <FaEye size='1.5em' />
            اضهار
          </label>
          <label className={`custom-radio ${type === 0 ? 'active' : ''}`} onClick={() => setType(0)}>
            <input type="radio" value={0} checked={type === 0} onChange={() => { }} />
            <FaEyeSlash size='1.5em' />
            اخفاء
          </label>
        </div>
        {/* <p>النوع المحدد: {type === 1 ? <FaEye size='1.5em' /> : <FaEyeSlash size='1.5em' />}</p> */}
      </div>
      <input
        type="text"
        placeholder="الوسوم"
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleAddTag();
          }
        }}
      />
      {/* <label>Tags:</label> */}
      <ul className='uledit'>
        {newTags.map((tag, index) => (
          <li className='liedit' key={index} style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
            #{tag}
            <button style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} onClick={() => handleRemoveTag(index)}>X</button>
          </li>
        ))}
      </ul>


    </div>
  );
};

export default Editcategory;
