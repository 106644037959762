import React, { useState, useEffect } from 'react';
import { url ,formatInputString } from '../../Config';
import Subcategorytable from './Subcategorytable'; // Import the Subcategorytable component
import Modal from '../../boxes/Modal'
import {MdOutlineAdd } from 'react-icons/md';
import './editsubiesss.css';


const CategoryDetails = ({ selectedCategory }) => {
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [newSubcategoryName, setNewSubcategoryName] = useState('');
  const [newSubcategoryTags, setNewSubcategoryTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addedsubnice, setaddedsubnice] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
    setaddedsubnice(false)
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewSubcategoryTags([]);
    setNewSubcategoryName('');
    setNewTag('')
    setSubcategoryData([])

  };
  // Function to fetch subcategories for the selected category
  const fetchSubcategories = async () => {
    try {
      const response = await fetch(`${url}/admin/subcategories/subcategories-table/${selectedCategory}`, {
        method: 'GET',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      });
      // console.log('this is :', response);
      if (response.ok) {
        const data = await response.json();
        setSubcategoryData(data.subcategories);
        // console.log('hadi li baghi=>',data,data.subcategories)
      } else {
        console.error('Failed to fetch subcategories');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Load subcategories when the component mounts or when the selected category changes
  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories();
    }
  }, [selectedCategory]);


  const addNewSubcategory = async () => {
    if (newSubcategoryName == '') {
      alert('Please fill subcategory Name.');
      return;
    }
    try {
      const response = await fetch(`${url}/admin/subcategories/add-subcategory`, {
        method: 'POST',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          categoryName: selectedCategory,
          subcategoryName: newSubcategoryName,
          subcategoryTags: JSON.stringify(newSubcategoryTags),
        }),
      });

      if (response.ok) {
        setaddedsubnice(true);
        fetchSubcategories();
        setNewSubcategoryName('');
        setNewSubcategoryTags([]);
        closeModal()
      } else {
        console.error('Failed to add subcategory');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Function to add a new tag to the list of tags
  const handleTagKeyPress = (e) => {
    if (e.key === 'Enter' && newTag.trim() !== '') {
      setNewSubcategoryTags([...newSubcategoryTags, formatInputString(newTag)]);
      setNewTag('');
    }
  };

  const handleNewSubcategoryNameChange = (e) => {
    // Replace spaces and special characters with underscores
    const updatedSubcategoryName = formatInputString(e.target.value);
    setNewSubcategoryName(updatedSubcategoryName);
  };

  function normalizeArabic(text) {
    return text
        .replace(/[أإآ]/g, 'ا') 
        // .replace(/ة/g, 'ه')    
        // .replace(/ى/g, 'ي') 
        .replace(/[ًٌٍَُّ]/g, '');
}
  return (
    <div>

      {/* Add new subcategory form */}
      <MdOutlineAdd
       onClick={openModal}
       style={{ cursor: 'pointer' ,color:'green' }}
       size='2em'
      />
      <br></br>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
      <div>
      <button onClick={addNewSubcategory} style={{position:'absolute',top:'20px' , right:'9px'}} >إضافة</button>
        {/* <h3>Add New Subcategory</h3> */}
        {/* <label htmlFor="newSubcategoryName">Name:</label> */}
        <input
          type="text"
          id="newSubcategoryName"
          value={newSubcategoryName}
          onChange={handleNewSubcategoryNameChange}
          placeholder='اسم الفرع'
        />
        {/* <hr></hr> */}
        {/* <label htmlFor="newTag">Tags:</label> */}
        {/* <input
          type="text"
          id="newTag"
          value={newTag}
          onChange={(e) => setNewTag(formatInputString(normalizeArabic(e.target.value)))}
          onKeyPress={handleTagKeyPress}
          placeholder='الوسوم'
        /> */}
        <div>
          {newSubcategoryTags.length > 0 && (
            <ul  className='uledit'>
              {newSubcategoryTags.map((tag, index) => (
                <li key={index} className='liedit' style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>{tag}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
      </Modal>
      {/* Render the Subcategorytable component */}
      <Subcategorytable selectedCategory={selectedCategory}  addedsubnice={addedsubnice} />
    </div>
  );
};

export default CategoryDetails;
