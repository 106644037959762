import React, { useState, useEffect } from 'react';
import Editsubcategory from './Editsubcategory';
import { url } from '../../Config';
import Modal from '../../boxes/Modal'
import { MdDelete, MdEdit } from 'react-icons/md';
import { useNavigate, Link } from 'react-router-dom';
import { DeleteModal } from '../../boxes/DeleteModal';
import Loading from '../../boxes/Loading';

import './sub.css'
import './editsubiesss.css';
// import './sub.css'

const Subcategorytable = ({ selectedCategory , addedsubnice }) => {
  // console.log(selectedCategory);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const [deletingCategoryID, setDeletingCategoryID] = useState(null);
  const [spnbtntag, setspnbtntag] = useState({ s: false, k: null, t: null });
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();


  // Function to fetch subcategories for the selected category
  const fetchSubcategories = async () => {
    try {
      const response = await fetch(`${url}/admin/subcategories/subcategories-table/${selectedCategory}`, {
        method: 'GET',
        headers: {
          "x-access-token": localStorage.getItem("token"), // Include the token in headers
        },
      });
      // console.log('this is :', response);
      if (response.ok) {
        const data = await response.json();
        setSubcategoryData(data.subcategories);
        setLoading(false); 
      } else {
        console.error('Failed to fetch subcategories');
        setLoading(false); // Set loading to false on error
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  // Load subcategories when the component mounts or when the selected category changes
  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (addedsubnice) {
      fetchSubcategories();
    }
  }, [addedsubnice]);



  const handleEditSubcategory = (subcategory) => {
    setEditingSubcategory(subcategory);
  };

  const handleSaveSubcategory = async (editingSubcategory) => {
    // console.log('selected cate=>', selectedCategory)
    // console.log('sub', editingSubcategory)
    try {
      // console.log(JSON.stringify(editingSubcategory))
      const response = await fetch(`${url}/admin/subcategories/${selectedCategory}/${editingSubcategory.id}`, {
        method: 'PUT',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingSubcategory),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error(`Failed to update subcategory. Error: ${errorMessage}`);
        // You can also throw an error or handle it in another way.
      } else {
        fetchSubcategories()
        handleCancelEdit()
        // Handle the successful response.
        // console.log('update subcategory successfully');
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingSubcategory(null);
  };


  const handleDeleteSubCategory = async (subcategory) => {
    try {
      const subcategorydata = { selectedCategory, subcategory };
      // console.log(JSON.stringify(subcategorydata))
      const response = await fetch(`${url}/admin/subcategories/delete-Subcategory`, {
        method: 'DELETE',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subcategorydata),
      });
      // console.log(response)
      if (response.ok) {
        fetchSubcategories()
        setDeletingCategoryID(null)
      } else {
        console.error('Failed to delet Subcategory');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Inside your Subcategories component
  const handleSubcategoryClick = (subcategoryName) => {
    // Construct the URL for Usermessages component with the selectedCategory and subcategoryName
    const url = `/Usermessages?Category=${encodeURIComponent(selectedCategory)}&subcategory=${encodeURIComponent(subcategoryName)}`;

    // Use the navigate function to navigate to the Usermessages component
    navigate(url);
  };

  if (loading) {
    return <> <Loading/><Loading/><Loading/><Loading/></>
  }

  return (
    <div>
      <div className='cardbox'>
        {subcategoryData.map((subcategory,key) => (
          <div key={subcategory.id} className='cardcatego' >
            <span className='titlecard'
              onClick={() => handleSubcategoryClick(subcategory.subcategorie_name)}>
             {subcategory.subcategorie_name.replaceAll('_', ' ')}
            </span>

            <span className='spantagsbtn' onClick={() => setspnbtntag({ s: true, k: key, t: subcategory.subcategorie_tags })}>#</span>


            <div className='actioncard'>
              <MdEdit
                onClick={() => handleEditSubcategory(subcategory)}
                style={{ cursor: 'pointer', color: '#03636C' }}
                size='1.5em'
              />

              <MdDelete
                onClick={() => setDeletingCategoryID(subcategory.subcategorie_name)} // Set the deletingCategoryID
                style={{ cursor: 'pointer', color: '#03636C' }}
                size='1.5em'
              />
            </div>

          </div>
        ))}

      </div>



      <div className={`cardtags ${spnbtntag.s === true ? 'active' : null} `} >
        <span className='closetagmdl' onClick={() => setspnbtntag({ s: false, k: null, t: null })}>x</span>
        {spnbtntag.t && (
          <ul className='uledit'>
            {JSON.parse(spnbtntag.t).map((tag, index) => (
              <li className='liedit' style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }} key={index}>#{tag}</li>
            ))}
          </ul>
        )}
      </div>



      {
        editingSubcategory && (
          <Modal isOpen={true} onClose={handleCancelEdit}>
            <div >
              <Editsubcategory
                subcategory={editingSubcategory}
                onSave={handleSaveSubcategory}
              />
            </div>
          </Modal>
        )
      }

      {
        deletingCategoryID && (
          <DeleteModal onDeleteConfirmed={() => handleDeleteSubCategory(deletingCategoryID)}  statedeletbtn={() => setDeletingCategoryID(null)} />
        )
      }

    </div >
  );
};

export default Subcategorytable;
