

import React, { useState, useEffect } from 'react';
import { url } from '../../Config';
import Editcategory from './Editcategory';
import axios from 'axios';
import Modal from '../../boxes/Modal';
import Loading from '../../boxes/Loading';
import { DeleteModal } from '../../boxes/DeleteModal';
import './Categoriesss.css';
// import './Categories.css';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { RxUpdate } from "react-icons/rx";
import { IoIosSettings } from "react-icons/io";




const Categorytable = ({ userRole }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [editingCategory, setEditingCategory] = useState(null);
  const [deletingCategoryID, setDeletingCategoryID] = useState(null);
  const [spnbtntag, setspnbtntag] = useState({ s: false, k: null, t: null });
  const [rangeValue, setRangeValue] = useState(100);
  const [filteredTags, setfilteredTags] = useState(JSON.parse(spnbtntag.t) || []);
  const [UpdateCatego, setUpdateCatego] = useState(null);
  const isAdmin = userRole === 'admin';
  const isSousadmin = userRole === 'sousadmin';
  const isSupAdmin = userRole === 'supadmin';


  const handleRangeChange = (event) => {
    const newRangeValue = parseInt(event.target.value, 10);
    setRangeValue(newRangeValue);

    if (spnbtntag.t) {
      const filter = JSON.parse(spnbtntag.t).filter(tag => tag.length <= newRangeValue);
      setfilteredTags(filter);
    }
  };
  useEffect(() => {
    if (spnbtntag.t) {

      setfilteredTags(JSON.parse(spnbtntag.t));
    }
  }, [spnbtntag.t])

  useEffect(() => {
    if (UpdateCatego) {
      handleUpdateCatego(UpdateCatego)
    }
  }, [UpdateCatego])





  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${url}/admin/categories/get-categories`, {
        headers: {
          "x-access-token": localStorage.getItem("token"), // Include the token in headers
        },
      });
      if (response.ok) {
        const data = await response.json();
        let categoadmin;

        if (isSupAdmin) {
          // If isSupAdmin is true, include all categories
          categoadmin = data.categories;
        } else {
          // If isSupAdmin is false, exclude the category 'معلومات_عامة'
          categoadmin = data.categories.filter(e => e.Category_id !== 1);
        }

        setCategories(categoadmin);
        setLoading(false);
      } else {
        console.error('Failed to fetch categories');
        setLoading(false); // Set loading to false on error
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false); // Set loading to false on error
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const handleUpdateCatego = async (UpdateCatego) => {
    try {
      const response = await fetch(`${url}/admin/usermessages/UpdateCatego/${UpdateCatego}`, {
        method: 'PUT',
        headers: {
          "x-access-token": localStorage.getItem("token"),
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201 || response.ok) {
        setTimeout(() => {
          fetchCategories();
          setUpdateCatego(null);
        }, 3000);
      } else {
        console.error('Error Updating Catego:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding message:', error);
    }
  };


  if (loading) {
    return <> <Loading /><Loading /><Loading /></>
  }

  const handleEditCategory = (category) => {
    setEditingCategory(category);
  };

  const handleSaveCategory = (updatedCategory) => {
    // Update the category data and send a request to the API
    // You'll need to implement the API request here
    // Once the API request is successful, update the state
    setCategories((prevCategories) =>
      prevCategories.map((c) => (c.Category_id === updatedCategory.Category_id ? updatedCategory : c))
    );
    setEditingCategory(null);
  };

  const handleCancelEdit = () => {
    setEditingCategory(null);
  };

  const handleDeleteCategory = async (categoryId) => {
    // console.log('Deleting category with ID:', categoryId);

    try {
      const response = await axios.delete(`${url}/admin/categories/${categoryId}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"), // Include the token in headers
        },
      });

      // console.log('Response status:', response.status);
      // console.log('Response data:', response.data);

      if (response.status === 200) {
        // If deletion is successful, update the state to remove the deleted category
        setCategories((prevCategories) =>
          prevCategories.filter((category) => category.Category_id !== categoryId)
        );
        // console.log(`Category with ID ${categoryId} deleted successfully`);
        setDeletingCategoryID(null)
      } else {
        console.error('Failed to delete category');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleCategoryClick = (categoryName) => {
    // Construct the URL for Subcategories component with the selected category
    const url = `/subcategories?category=${encodeURIComponent(categoryName)}`;

    // Use the navigate function to navigate to the Subcategories component
    navigate(url);
  };

  return (
    <div>
      <div className='cardbox'>
        {categories.map((category, key) => (
          <div key={category.Category_id} className={`cardcatego ${category.Category_id === 1 ? 'nlpcatego' : ''}`}>
            <div className='title-update'>
              <span className='titlecard'
                onClick={() => handleCategoryClick(category.Category_name)}>
                {category.Category_name.replaceAll('_', ' ')}
              </span>
              <RxUpdate
                onClick={() => setUpdateCatego(category.Category_id)}
                style={{ cursor: 'pointer', color: '#03636C' }}
                size='1em'
              />
            </div>



            <span className='spantagsbtn' onClick={() => setspnbtntag({ s: true, k: key, t: category.Category_tags })}>#</span>


            <div className='actioncard'>
              {category.type === 1 ? (
                <FaEye
                  style={{ cursor: 'pointer', color: '#03636C' }}
                  size='1.5em'
                />
              ) : (
                <FaEyeSlash
                  style={{ cursor: 'pointer', color: '#03636C' }}
                  size='1.5em'
                />
              )}
              {category.Category_id === 1 ? (
                <FaLock
                  style={{ cursor: 'pointer', color: '#03636C' }}
                  size='1.5em'
                />
              ) : (
                <>
                  <MdEdit
                    onClick={() => handleEditCategory(category)}
                    style={{ cursor: 'pointer', color: '#03636C' }}
                    size='1.5em'
                  />

                  <MdDelete
                    onClick={() => setDeletingCategoryID(category.Category_id)}
                    style={{ cursor: 'pointer', color: '#03636C' }}
                    size='1.5em'
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={`cardtags ${spnbtntag.s === true ? 'active' : ''}`}>
        <span className='closetagmdl' onClick={() => {
          setspnbtntag({ s: false, k: null, t: null });
          setRangeValue(100);
          setfilteredTags([]);
        }}>x</span>
        <input
          className='rangemodal'
          type="range"
          min={spnbtntag.t ? JSON.parse(spnbtntag.t).map(tag => tag.length).reduce((min, length) => Math.min(min, length), Infinity) : 0}
          max={spnbtntag.t ? JSON.parse(spnbtntag.t).map(tag => tag.length).reduce((max, length) => Math.max(max, length), 0) : 0}
          step="1"
          value={rangeValue}
          onChange={handleRangeChange}
        />
        {spnbtntag.t && (
          <ul className='uledit'>
            {filteredTags.map((tag, index) => (
              <li className='liedit' style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }} key={index}>#{tag}</li>
            ))}
          </ul>
        )}
      </div>
      {/* <table className="custom-table">
        <thead>
          <tr>
            <th>اسم الفئة</th>
            <th>العلامات</th>
            <th>type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.Category_id}>
            
              <button onClick={() => handleCategoryClick(category.Category_name)}>
                {category.Category_name}
              </button>
              <td>
                {category.Category_tags && (
                  <ul className="custom-list">
                    {JSON.parse(category.Category_tags).map((tag, index) => (
                      <li key={index}>{tag}</li>
                    ))}
                  </ul>
                )}
              </td>
              <td>
                {category.type === 1 ? "تبان" : "ماتبانش"}
              </td>
              <td id='action'>
                <MdEdit
                  onClick={() => handleEditCategory(category)}
                  style={{ cursor: 'pointer' }}
                  size='2em'
                />

                <MdDelete
                  onClick={() => setDeletingCategoryID(category.Category_id)} // Set the deletingCategoryID
                  style={{ cursor: 'pointer', color: 'red' }}
                  size='2em'
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}

      {editingCategory && (
        <Modal isOpen={true} onClose={handleCancelEdit}>
          <Editcategory
            category={editingCategory}
            onSave={handleSaveCategory}
          />
        </Modal>
      )}

      {UpdateCatego && (
        <div id='wheel-update-catego'>
          <IoIosSettings className="wheelturnu" />
        </div>
      )}

      {deletingCategoryID && (
        <DeleteModal onDeleteConfirmed={() => handleDeleteCategory(deletingCategoryID)} statedeletbtn={() => setDeletingCategoryID(null)} />
      )}


    </div>
  );
};

export default Categorytable;
