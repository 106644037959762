import React, { useState } from 'react';
import {formatInputString} from '../../Config'
import './editsubiesss.css';

const Editsubcategory = ({ subcategory, onSave }) => {
  const [editedSubcategoryName, setEditedSubcategoryName] = useState(
    subcategory.subcategorie_name
  );
  const [editedSubcategoryTags, setEditedSubcategoryTags] = useState(
    JSON.parse(subcategory.subcategorie_tags).map((e) => e)
  );
  const [newSubcategoryTags, setNewSubcategoryTags] = useState([]);
  const [newTag, setNewTag] = useState('');

  const handleSave = () => {
    // Create an object with edited subcategory data
    const editedSubcategory = {
      ...subcategory,
      subcategorie_name: editedSubcategoryName, // Update the name field
      subcategorie_tags: [...newSubcategoryTags, ...editedSubcategoryTags], // Update the tags field as an array
    };
  
    // Call the onSave function to save the edited subcategory
    onSave(editedSubcategory);
  };
  

  // Function to add a new tag to the list of tags
  const handleTagKeyPress = (e) => {
    if (e.key === 'Enter' && newTag.trim() !== '') {
      setNewSubcategoryTags([...newSubcategoryTags, newTag]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setEditedSubcategoryTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };
  
  const handleRemoveNewTag = (tagToRemove) => {
    setNewSubcategoryTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  function normalizeArabic(text) {
    return text
        .replace(/[أإآ]/g, 'ا') 
        // .replace(/ة/g, 'ه')    
        // .replace(/ى/g, 'ي') 
        .replace(/[ًٌٍَُّ]/g, '');
}
  
  return (
    <div>
       <button onClick={handleSave} style={{position:'absolute',top:'20px' , right:'9px'}}>حفض</button>
    <input
      type="text"
      value={editedSubcategoryName.replaceAll('_',' ')}
      onChange={(e) => setEditedSubcategoryName(e.target.value)}
    />
        <input
      type="text"
      id="newTag"
      value={newTag}
      onChange={(e) => setNewTag(formatInputString(normalizeArabic(e.target.value)))}
      onKeyPress={handleTagKeyPress}
      placeholder='الوسوم'
    />
        <div>
      {newSubcategoryTags.length > 0 && (
        <ul className='uledit'>
          {newSubcategoryTags.map((tag, index) => (
            <li key={index} className='liedit' style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
              {tag}
              {/* <button onClick={() => handleRemoveNewTag(tag)}>Remove</button> */}
              <button style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} onClick={() => handleRemoveNewTag(tag)}>X</button>

            </li>
          ))}
        </ul>
      )}
    </div>
    {/* <hr></hr> */}
    <div>
      <ul className='uledit'>
        {editedSubcategoryTags.map((tag, index) => (
          <li key={index} className='liedit' style={{ marginLeft: '10px', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#a6d5e478' }}>
            {tag}
            {/* <button onClick={() => handleRemoveTag(tag)}>Remove</button> */}
            <button style={{ marginRight: '10px', fontSize: '16px', color: 'red', padding: '0px', fontFamily: "Comic Sans MS", backgroundColor: 'transparent' }} onClick={() => handleRemoveTag(tag)}>X</button>
          </li>
        ))}
      </ul>
    </div>
    {/* <label htmlFor="newTag">Tags:</label> */}
  </div>
  
  );
};

export default Editsubcategory;
