import React from 'react';
import './resulttuto.css'
import { parseStyledText } from '../../../Config'

function Resulttuto({ result, mssg, userRole, chatmsg }) {
  const isAdmin = userRole === 'admin';
  const isSousadmin = userRole === 'sousadmin';
  const isSupAdmin = userRole === 'supadmin';

  return (
    <div id='resultcont'>
      <table className="result-table">
        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            <th>المصطلح</th>
            <th>الفئة</th>
            <th> الفرع</th>
            <th>الاجابة</th>
            <th>المقترح</th>
          </tr>
        </thead>
        <tbody>
          {result !== null ?
            result.map((item, index) => (
              <tr key={index}>
                <td className='tiri firsttd'>{item.term.replaceAll('_', ' ')}</td>
                {isSupAdmin ? (
                  <>
                    <td className='tiri'>{item.category && item.category.replaceAll('_', ' ') || `Social messages` || '-- NLP --'}</td>
                    <td className='tiri'>{item.subcategory && item.subcategory.replaceAll('_', ' ') || `${item.type}` || '-- NLP --'}</td>
                  </>
                ) : (
                  <>
                    <td className='tiri'>{item.category && (item.category !== 'nlp_farid_secure' ? item.category.replaceAll('_', ' ') : '-- NLP --') || '-- NLP --'}</td>
                    <td className='tiri'>{item.subcategory && (item.category !== 'nlp_farid_secure' ? item.subcategory.replaceAll('_', ' ') : '-- NLP --') || '-- NLP --'}</td>
                  </>
                )}
                {/* <td>{item.rep.rep || item.rep}</td> */}
                <td>
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    width:'200px',
                    margin: '0px',
                    textAlign: 'right',
                    lineHeight: '1.5',
                    padding: '0px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                  dir='rtl' dangerouslySetInnerHTML={{ __html: parseStyledText(item.rep.rep || item.rep)}} />
                  </td>
                {/* <td>{item.rep.options && item.rep.options || '----'}</td> */}
                <td id='stopflow'>
                  {item.rep.options && JSON.parse(item.rep.options).map((option, index) => (
                  <>  <a href={option.value} target="_blank">
                      <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>
                        {option.label}
                      </span>
                    </a><br></br></>
                  ))}
                </td>

              </tr>
            ))
            : (
              <tr>
                <td className='tiri firsttd'>{mssg}</td>
                <td className='tiri'>-- NLP --</td>
                <td className='tiri'>-- NLP --</td>
                {/* <td>{chatmsg}</td> */}
                <td>
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    width:'auto',
                    margin: '0px',
                    textAlign: 'right',
                    lineHeight: '1.5',
                    padding: '0px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                  dir='rtl' dangerouslySetInnerHTML={{ __html: parseStyledText(chatmsg)}} />
                </td>
                <td></td>
              </tr>)
          }
        </tbody>
      </table>
    </div>
  );
}

export default Resulttuto;
